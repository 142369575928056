
import { useEffect, useState } from "react";
import { Form, Row, Col, Button, Switch, Select } from "antd";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import AccountData from "../../../models/AccountData";
import { KeyValue } from "../../../models/CommunicationsData";
import './ValidateAccountForm.scss';

interface ValidateAccountFormProps {
    drawerVisible: boolean;
    setDrawerVisible: (visible: boolean) => void;
    accountData: AccountData | undefined;
    contracts: KeyValue[] | undefined;
    onValidateAccount: (id: string, isActive: boolean, typeAccount: string, contract: string | "") => Promise<void>;
}


const ValidateAccountForm = (props: ValidateAccountFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [loadingButton, setLoadingButton] = useState<boolean>(false);

    const closeDrawer = () => {
        form.resetFields();
        props.setDrawerVisible(false)
    }





    const onFinish =  async (values: any) => {
        if (props.accountData?.id) {
            try{
                setLoadingButton(true)
                await props.onValidateAccount(props.accountData.id, values.isActive, props.accountData?.type, values.contract)               

            }finally{
                setLoadingButton(false)
                closeDrawer();
            }

        }

    };



    return (
        <div className='validate-account-form-component'>

            <Form form={form}
                onFinish={onFinish}
                layout="vertical"
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="isActive"
                            valuePropName="checked"
                            label={t("components.accounts.list.form-validation.activate-account")}
                            initialValue={true}
                        >
                            <Switch className="app-switch" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="contract"
                            className="contract-selector"
                            label={t("components.accounts.list.form-validation.contract")}
                            initialValue={props.contracts?.[0].key}>
                            <Select className="app-button"
                                defaultValue={props.contracts?.[0].key}>
                                {props.contracts?.map((contract, index) => {
                                    return (
                                        <Select.Option key={index} value={contract.key}>
                                            {contract.value}
                                        </Select.Option>
                                    );
                                })}

                            </Select>

                        </Form.Item>
                    </Col>


                    <Col xs={24} md={24}>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button className="app-button button-style" htmlType="submit" disabled={loadingButton}>
                                {loadingButton ? <LoadingOutlined /> : t("button.apply-changes")}
                            </Button>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>

        </div>
    );
};

export default ValidateAccountForm