import React, { useContext, useEffect, useState } from 'react';
import './ClientNumberComponent.scss';
import { useTranslation } from "react-i18next";

import { Divider } from 'antd';
import { Typography } from 'antd';
import { useAppConfig } from '../../../Contexts/AppConfigContext';



// Componente Base de la parte publica de nuestra aplicación
export const ClientNumberComponent = () => {

    const appConfig = useAppConfig();
    const { t } = useTranslation();
    const [clientNumber, setClientNumber] = useState<string>("");


    useEffect(() => {
        if (appConfig != null && appConfig != undefined &&  appConfig.config.clientData.identityCode != null && appConfig.config.clientData.identityCode != undefined && appConfig.config.clientData.name != null && appConfig.config.clientData.name != undefined) {
            const c: string =
                "PRO-" +
                appConfig.config.clientData.name.substring(0, 3).toUpperCase() +
                "-" +
                appConfig.config.clientData.identityCode.slice(-4);

            setClientNumber(c);
        }else {
            setClientNumber("");
        }



    }, [appConfig]);



    return (
        <div className="client-number-component">
            {clientNumber != "" &&
                (<>
                    <Divider className="separator-client" />
                    <Typography.Paragraph
                        className="label-client"
                    >
                        {t("menus.private.client-number")}
                    </Typography.Paragraph>
                    <Typography.Paragraph
                        className="label-number-client"
                    >
                        {clientNumber}
                    </Typography.Paragraph>

                </>
                )}

        </div>
    );
}
export default ClientNumberComponent;   