import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import './FilterCommunicationsComponent.scss';
import { newStatus, newStatusSelector, PageSize } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { NewsFilter } from '../../../models/CommunicationsData';

interface FilterCommunicationsComponentProps {
    onSearch: (data: NewsFilter) => void;
    searching: boolean;
}

const FilterCommunicationsComponent = (props: FilterCommunicationsComponentProps): JSX.Element => {
    const { t } = useTranslation();
    const { searching } = props;

    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.onSearch({
            title: values.title,
            status: values.status,
            page: 0,
            pageSize: PageSize
        });
       
    };
    const items = newStatusSelector;


    return (
        <div className="communications-filter">
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={16} >
                    <Col xs={24} md={10}>
                        <Form.Item
                            name="title"
                            label={t('components.communications.filter.tittle')}
                            initialValue=""
                        >
                            <Input className="app-input" allowClear />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            name="status"
                            label={t('components.communications.filter.status')}
                            initialValue=""
                        >
                            <Select className="app-input" defaultValue="" allowClear>
                                {items.map((item) => (
                                    <Select.Option key={item.key} value={item.key}>
                                        {t(item.label)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label=" " className="button-label-fix">
                            <Button
                                disabled={searching}
                                className="app-button"
                                htmlType="submit"
                                loading={searching}
                            >
                                {   searching  ? (t("button.searching")) :   t("button.search") }
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default FilterCommunicationsComponent;
