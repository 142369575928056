import { Drawer, FloatButton, Layout } from "antd";
import {
    LanguageSelector,
  PrivateFooter,
  PrivateHeader,
  PrivateMenu,
} from "../../components/common";
import { useEffect, useState } from "react";

import "./PrivatePageWrapper.scss";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { useAppConfig } from "../../Contexts/AppConfigContext";
import { useCloseSession } from "../../api/repositoryEP";
import { useSessionData } from "../../Contexts/SessionDataContext";
import { GetFormattedUrl } from "../../utils/urls";
import { hexToRgb } from "../../utils/utils";

interface PrivatePageWrapperProps {
  children: JSX.Element;
}

const PrivatePageWrapper = (props: PrivatePageWrapperProps): JSX.Element => {
  const appConfig = useAppConfig();
  const { sessionData } = useSessionData();

  const closeSession = useCloseSession;

  const [style, setStyle] = useState<string>("");

  useEffect(() => {
    setStyle(`
              :root {
                --rgba-05: rgba(${hexToRgb(
                  appConfig.config.secondaryColor
                )}, 0.1);
                --primary-app-color: ${appConfig.config.mainColor};
                --secondary-app-color: ${appConfig.config.secondaryColor};
                .solbooking-calendar{
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
                }
              }
            `);
  }, [appConfig]);

  const [collapsed, setCollapsed] = useState(true);

  useEffect(()=>{
    switch(appConfig.device){
        case "mobile":
            setCollapsed(true);
            break;
        case "tablet":
            setCollapsed(true);
            break;
        default:
            setCollapsed(false);
            break;
    }
  },[appConfig.device])

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const doLogout = (): void => {
    closeSession(sessionData.userData.id)
      .then( (res) =>
        window.location.href = GetFormattedUrl("")
      )
      
  };

  return (
    <Layout className="private-page-wrapper">
      <style>{style}</style>
      <PrivateHeader toogleCollapse={toggleCollapsed} doLogout={doLogout} />
      <Layout className="private-content">
        {appConfig.device === "mobile" ? (
          <Drawer
            placement="left"
            open={!collapsed}
            onClose={toggleCollapsed}
            width={256}
            className="private-menu-mobile-placement"
          >
            <PrivateMenu onSelectItem={toggleCollapsed}/>
          </Drawer>
        ) : (
          <Sider
            id="sider"
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth={60}
            width={250}
            className="site-layout"
          >
            <PrivateMenu onSelectItem={(selectedItem: string)=>{}}/>
          </Sider>
        )}

        <Content className="private-children content-page" id="content-page">
          {props.children}

          <FloatButton.BackTop
            className="private-scroll-top"
            visibilityHeight={0}
            onClick={() => {
              const scrollTopElement = document.getElementById("content-page")!;
              scrollTopElement.scrollTop = 0;
            }}
          />
        </Content>
      </Layout>
      <PrivateFooter />
    </Layout>
  );
};

export default PrivatePageWrapper;
