import { createContext, useContext, useEffect, useState } from "react";
import UserSessionData from "../models/UserSessionData";

import { GetDefaultLanguage, GetLanguageInUrl, IsLaguagePresentInUrl } from "../utils/urls";

const defaultSessionData: UserSessionData = {
    sessionId: "",
    userData: {
        id: "",
        name: "",
        surname: "",
        secondSurname: "",
        UAID: "",
        avatar: "",
        email: "",
        phone: "",
        username: "",
        defaultLanguage: "",
        role: "",
        permissions: [],
    },
    isLogged: false,
};

// 🔹 Crear el contexto con un objeto vacío inicial
const SessionDataContext = createContext<{
    sessionData: UserSessionData;
    setSessionData: (data: UserSessionData) => void;
}>({
    sessionData: defaultSessionData,
    setSessionData: () => {}
});

export const SessionDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [sessionData, setSessionData] = useState<UserSessionData>(defaultSessionData);

    const checkPage = (): void => {
        var path = window.location.pathname;
        const lang: string = IsLaguagePresentInUrl() ? GetLanguageInUrl() : GetDefaultLanguage();

        if (path.includes("private")) {
            // Si está en una página privada, redirigir a 403
            window.location.href = `${lang}/forbiden/no-session-found`;
        }
    };

    useEffect(() => {
        const storedSession = sessionStorage.getItem("user-data");

        if (storedSession) {
            setSessionData(JSON.parse(storedSession) as UserSessionData);
        } else {
            checkPage();
        }
    }, []);

    return (
        <SessionDataContext.Provider value={{ sessionData, setSessionData }}>
            {children}
        </SessionDataContext.Provider>
    );
};

// 🔹 Hook para acceder directamente a `sessionData`
export const useSessionData = () => useContext(SessionDataContext);
