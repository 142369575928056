import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Contracts'

export const ContractsEP : EndPointConfig[] =[

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetContracts"),  

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("AddContract"),  
        
        new EndPointConfig()
        .setMethod("PATCH")
        .setController(controller)
        .setActionName("UpdateContract"),    
        
        new EndPointConfig()
        .setMethod("PATCH")
        .setController(controller)
        .setActionName("SetAsBase"),    

        new EndPointConfig()
        .setMethod("PATCH")
        .setController(controller)
        .setActionName("ChangeContractStatus"),    

        new EndPointConfig()
        .setMethod("DELETE")
        .setController(controller)
        .setActionName("DeleteContract"),

        
        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetAllContracts"),

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetContract")
]