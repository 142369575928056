
import './UsersPage.scss'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { UsersFilterData2 } from '../../../models/UsersFilterData';
import { CustomPagination } from '../../../components';

import { filterUsers } from '../../../api/repositoryEP';
import { UsersList } from '../../../components';
import { UsersFilter } from '../../../components';
import { UserData } from '../../../models/UserData';



const UsersPage = (): JSX.Element => {

    const defaultPageSize = 10;


    const [searching, setSearching] = useState<boolean>(false);
    const [accountList, setAccountList] = useState<UserData[]>([]);

    const [actualPage, setActualPage] = useState<number>(1);
    const [actualPageSize, setActualPageSize] = useState<number>(defaultPageSize);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [actualFilter, setActualFilter] = useState<UsersFilterData2>({
        name: '',
        state: '',
        role: '',
        email: '',
        username: '',
        page: 0,
        pageSize: defaultPageSize,
    });

    const onChangePagination = (newPage: number, newPageSize?: number | undefined) => {
        setActualPage(newPage);
        setActualPageSize(newPageSize ?? defaultPageSize);
        setActualFilter({
            ...actualFilter,
            page: newPage - 1,
            pageSize: newPageSize ?? defaultPageSize
        });
    }


    const onSearch = () => {
        setSearching(true);
        filterUsers(actualFilter).then((r) => {
            if (r?.type === 1 && r?.data) {
                setAccountList(r?.data.elements as UserData[]);
                setTotalRecords(r?.data.totalRecords as number);
            } else {
                setAccountList([]);
                setTotalRecords(0);
            }
        }).finally(() => {
            setSearching(false);
        })
    }

    useEffect(() => {
        onSearch();
    }, [actualFilter]);



    return (
        <div className="users-page">
            <div className="list-container">
                <UsersFilter onSearch={setActualFilter} searching={searching} />
                <UsersList list={accountList} loading={searching} onSearch={setActualFilter} />
            </div>
            {(!searching) &&
                <div className="pagination-container">
                    <CustomPagination actualPage={actualPage} actualPageSize={actualPageSize} totalElements={totalRecords} onChange={onChangePagination} />
                </div>
            }

        </div>
    )
}

export default UsersPage;