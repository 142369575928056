import { useEffect, useState } from 'react';
import AccountData from '../../../models/AccountData';
import './AccountUsersPage.scss';
import { useTranslation } from 'react-i18next';
import { changeAccountUserStatus, fetchUserAccounts, ResendConfirmationEmail } from '../../../api/repositoryEP';
import { AccountUsersList, AccountUsersFilter } from '../../../components';
import { AccountUserData } from '../../../models/AccountUserData';
import { AccountUsersAdministrationFilter } from '../../../models/AccountsFilter';

interface AccountUsersPageProps {
    accountData?: AccountData;
}

const AccountUsersPage = (props: AccountUsersPageProps): JSX.Element => {
    const { t } = useTranslation();
    const { accountData } = props;


    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [accountUsers, setAccountUsers] = useState<AccountUserData[]>([]);


    const onChangeUserStatus =  async (userId: string, status: string, oldStatus : string): Promise<void> => {
        await changeAccountUserStatus(userId, status, oldStatus).then((r) => {
            if(r?.type == 1){
                setAccountUsers((prevList) => prevList.map((item) => item.id === userId ? { ...item, status: status ? 'active' : 'inactive' } : item));
            }
        });
    };

    

    const onResendConfirmationEmail =  async (userId: string): Promise<void> => {
        await ResendConfirmationEmail(userId);
        
    };

    // Función para obtener la lista de usuarios según el filtro
    const getAccountUsers = (values: any) => {
        setIsLoading(true);
        
        // Crear el objeto de filtro con los valores del formulario y la cuenta
        const filterData: AccountUsersAdministrationFilter = {
            name: values.name || '',
            email: values.email || '',
            status: values.status || '',
            location: '',
            accountType: accountData?.type || '',
            id: accountData?.id || '',
        };

        fetchUserAccounts(filterData).then((r) => {
            if (r?.type === 1 && r?.data) {             
                setAccountUsers(r?.data as AccountUserData[]);
            } else {
                setAccountUsers([]);
            }
            setIsLoading(false);
        });
    };


    useEffect(() => {
        if (accountData) {
            getAccountUsers({}); 
        }
    }, [accountData]);

    return (
        <div className="account-users-page">
            <AccountUsersFilter onSearch={getAccountUsers} searching={isLoading} /> 
            <AccountUsersList list={accountUsers} onResendConfirmationEmail={onResendConfirmationEmail} onChangeUserStatus={onChangeUserStatus} loading={isLoading} />
        </div>
    );
};

export default AccountUsersPage;
