import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import { Alert, Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import "./EditUserForm.scss";
import { editUser, isValidUsername } from "../../api/repositoryEP";
import { Dispatch, SetStateAction } from 'react';
import EditUserData from "../../models/EditUserData";
import { UserData } from "../../models/UserData";
import { useSessionData } from "../../Contexts/SessionDataContext";
import UserSessionData from "../../models/UserSessionData";
import { permissionBoBasic, permissionBoManager, permissionsSuperAdmin, sectionOptions, Sections } from "../../utils/constants";



interface EditUserFormProps {
    setDrawerVisible: Dispatch<SetStateAction<boolean>>;
    userId: string | undefined
    user: UserData
    languagesAvailable: string[];

}

const EditUserForm = (props: EditUserFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [usernameValue, setUsernameValue] = useState<string>("");
    const [isValidUsernameInput, setIsValidUsernameInput] = useState<boolean>(true);
    const [passwordValue, setPasswordValue] = useState<string>("");
    const [isEmptyUsername, setIsEmptyUsername] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [hasInvalidCharacters, setHasInvalidCharacters] = useState(false);
    const { sessionData, setSessionData } = useSessionData();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState<string | undefined>();

    useEffect(() => {
  

        setSelectedRole(props.user.role);
        if (props.user && form) {
            form.setFieldsValue({
                name: props.user.name,
                surname: props.user.surname,
                "second-surname": props.user.secondSurname,
                email: props.user.email,
                phone: props.user.phone,
                username: props.user.username,
                language: props.user.languaje.slice(-2).toLowerCase(), // 
            });

            if (sessionData.userData.role === "super_admin") {
                form.setFieldsValue({
                    role: props.user.role,
                    permissions: props.user.permissions.map(permission => permission.section),
                });
                setSelectedRole(props.user.role);
            }

        }
    }, [props.user, form]);


    const handleInputBlur = async () => {
        setIsValidUsernameInput(true);
        setHasInvalidCharacters(false);

        const regex = /^[a-zA-Z0-9]+$/; // Solo letras y números, sin espacios ni caracteres especiales

        if (usernameValue === "") {
            setIsEmptyUsername(true);
        } else if (!regex.test(usernameValue)) {
            setHasInvalidCharacters(true);
        } else {
            setIsEmptyUsername(false);
            setHasInvalidCharacters(false);

            const response = await isValidUsername(usernameValue); // Tu función asíncrona
            if (response?.type === 1) {
                setIsValidUsernameInput(response.data);
            } else {
                setIsValidUsernameInput(false);
            }
        }
    };

    const closeDrawer = () => {
        form.resetFields();
        setSelectedRole(undefined);
        
        props.setDrawerVisible(false)
    }


    // CAMBIAR EL ONFINISHI
    const onFinish = (values: any) => {
        if (isValidUsernameInput) {
            // creamos el objeto con los datos del formulario y llamamos a la api
            setLoading(true)
            const userData: EditUserData = {
                id: props.user.id,
                name: values.name,
                surname: values.surname,
                lastSurname: values["second-surname"],
                email: values.email,
                phone: values.phone,
                username: values.username,
                password: values.password,
                rol: (sessionData.userData.role == "super_admin") ? values.role : props.user.role, // solo se edita el rol y los permisos si soy super admin
                language: values.language,
                permissions: (sessionData.userData.role == "super_admin") ? values.permissions : props.user.permissions.map(permission => permission.section)
            }

            editUser(userData)
                .then((response) => {
                    if (response?.type === 1 && response.data == true) {
                        // si esto ocurre es porque yo soy el superadmin y mi rol, se lo paso al usuario. entonces tengo que cambiarlo del context
                        if (sessionData.userData.role == "super_admin" && userData.rol === "super_admin") {
                            const storedUserData = window.sessionStorage.getItem("user-data");
                            if (storedUserData) {
                                const parsedUserData: UserSessionData = JSON.parse(storedUserData);

                                // Actualizar solo el rol
                                parsedUserData.userData.role = "bo_manager"; // Cambia "nuevoRol" por el valor que necesites

                                // Guardar nuevamente en sessionStorage
                                window.sessionStorage.setItem("user-data", JSON.stringify(parsedUserData));
                                setSessionData(parsedUserData);
                            }

                        }
                        closeDrawer();
                    }
                    setLoading(false)
                })
        }
    };




    useEffect(() => {
    }, [selectedRole]);



    const handleBtnCancel = () => {
        closeDrawer();
    }

    return (
        <div className="account-users-filter">
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={16}>

                    <Col xs={24} md={24}>
                        <h3>
                            {t("components.edit-users.general")}
                        </h3>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="name"
                            initialValue={props.user.name}
                            rules={[{ required: true, message: t("components.edit-users.validations.name-required") ?? "Name is required" }]}
                        >
                            <Input className="app-input"
                                placeholder={'' + t("components.edit-users.name")} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="surname"
                            initialValue={props.user.surname}
                            rules={[{ required: true, message: t("components.edit-users.validations.surname-required") ?? "Surname is required" }]}
                        >
                            <Input className="app-input"
                                placeholder={t("components.edit-users.surname") + ''} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="second-surname"
                            initialValue={props.user?.secondSurname}
                        >
                            <Input className="app-input"
                                placeholder={t("components.edit-users.second-surname") + ''} />
                        </Form.Item>
                    </Col>


                    <Col xs={24} md={24}>
                        <h3>
                            {t("components.edit-users.contact")}
                        </h3>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="email"
                            initialValue={props.user.email}
                            rules={[{ required: true, message: t("components.edit-users.validations.email-required") + "" }]}

                        >
                            <Input className="app-input"
                                placeholder={t("components.edit-users.email") + ""} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="phone"
                            initialValue={props.user.phone}
                            rules={[
                                {
                                    pattern: /^[0-9]*$/,
                                    message: t("components.edit-users.validations.phone-numeric") + "",
                                },
                            ]}
                        >
                            <Input className="app-input"
                                placeholder={t("components.edit-users.phone") + ""} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <h3>
                            {t("components.edit-users.access")}
                        </h3>
                    </Col>


                    <Col xs={24} md={24}>
                        <Form.Item
                            name="password"
                        >
                            <Input.Password className="app-input"
                                placeholder={t("components.edit-users.password") + ""}
                                value={passwordValue} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="repeat-password"
                            dependencies={['password']}

                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const passwordFieldValue = getFieldValue('password');
                                        if (!passwordFieldValue && !value) {
                                            // Ambos campos están vacíos, no hay error
                                            return Promise.resolve();
                                        }
                                        if (!value && passwordFieldValue) {
                                            return Promise.reject(t("components.edit-users.validations.password-required"))
                                        }
                                        if (!value || passwordFieldValue === value) {
                                            // El campo de repetir contraseña está vacío o coincide con el campo de contraseña
                                            return Promise.resolve();
                                        }
                                        // Los campos no coinciden
                                        return Promise.reject(t("components.edit-users.validations.password-not-match"));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                className="app-input"
                                placeholder={t("components.edit-users.repeat-password") + ""}
                            />
                        </Form.Item>
                    </Col>


                    <Col xs={24} md={24}>

                        <Form.Item
                            name="username"
                            initialValue={props.user.username}
                            rules={[
                                {
                                    required: true,
                                    message: t("components.new-users.validations.username-required") + "" // Traducción con t()
                                },
                            ]}
                            validateStatus={(!isValidUsernameInput || isEmptyUsername || hasInvalidCharacters) ? 'error' : 'success'}
                            help={isEmptyUsername
                                ? t("components.new-users.validations.username-required")
                                : hasInvalidCharacters
                                    ? t("components.new-users.validations.username-caracteres-not-valid") // Mensaje específico para caracteres no válidos
                                    : !isValidUsernameInput && t("components.new-users.validations.username-not-valid")} // Mensaje de formato no válido
                        >
                            <Input className="app-input"
                                placeholder={t("components.new-users.username") + ""} // Traducción con t()
                                value={usernameValue}
                                onChange={(e) => setUsernameValue(e.target.value)} // Actualiza el valor del estado
                                onBlur={handleInputBlur} // Valida el input al perder el foco
                            />
                        </Form.Item>
                    </Col>



                    { // esta parte del forumario solo se muestra, si mi rol es super admin 

                        sessionData.userData.role === "super_admin" && ( // si mi rol es super admin entonces le puedo cambiar el rol
                            <>
                                <Col xs={24} md={24}>
                                    <h3>
                                        {t("components.edit-users.role")}
                                    </h3>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item name="role"
                                    //    initialValue={props.user.role}
                                        rules={[{ required: true, message: t("components.edit-users.validations.rol-required") + "" }]} >
                                        <Select
                                            className="app-input"
                                            placeholder={t("components.edit-users.select")}
                                            onChange={(value) => {
                                                setSelectedRole(value); // Actualiza el estado
                                             
                                                if (value === "super_admin") {
                                                    setShowAlert(true);
                                                    form.setFieldsValue({ permissions: permissionsSuperAdmin });
                                                } else {
                                                    setShowAlert(false);
                                                    form.setFieldsValue({ acceptChangeRole: undefined });

                                                    if (value === "bo_manager") {
                                                        form.setFieldsValue({ permissions: permissionBoManager });
                                                    } else if (value === "bo_basic") {
                                                        form.setFieldsValue({ permissions: permissionBoBasic });
                                                    } else {
                                                        form.setFieldsValue({ permissions: [] });
                                                    }
                                                }
                                            }}>
                                            <Select.Option value="super_admin">{t("roles.super_admin")}</Select.Option>
                                            <Select.Option value="bo_manager">{t("roles.bo_manager")}</Select.Option>
                                            <Select.Option value="bo_basic">{t("roles.bo_basic")}</Select.Option>
                                            <Select.Option value="bo_customized">{t("roles.bo_customized")}</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={24}>
                                    <Form.Item
                                        name="permissions"
                                     //   initialValue={props.user.permissions.map(permission => permission.section)}
                                        >
                                        <Select
                                            className="app-select"
                                            mode="multiple"
                                            placeholder={t("components.edit-users.permission")}
                                            onChange={(value) => { }}
                                            disabled={selectedRole !== "bo_customized"} // Se habilita solo si es "bo_customized"
                                        >
                                            {sectionOptions.map((section: Sections) => (
                                                <Select.Option key={section} value={section}>
                                                    {t(`sections.${section}`)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {showAlert  && (
                                    <>

                                        <Form.Item
                                            name="acceptChangeRole"
                                            valuePropName="checked"
                                            rules={[
                                                {
                                                    validator: (_, value) =>
                                                        value ? Promise.resolve() : Promise.reject((t("components.edit-users.validations.checkbox-required")))
                                                }
                                            ]}
                                        >
                                            <Checkbox className="app-checkbox">
                                                {t("components.edit-users.checkbox-super-admin")}
                                            </Checkbox>
                                        </Form.Item>
                                    </>
                                )}


                            </>
                        )
                    }



                    <Col xs={24} md={24}>
                        <h3>
                            {t("components.edit-users.language")}
                        </h3>
                    </Col>


                    <Col xs={24} md={24}>
                        <Form.Item
                            name="language"
                            rules={[{ required: true, message: t("components.new-users.validations.language-required") + "" }]}
                        >
                            <Select className="app-input" placeholder={t("components.new-users.select")}>
                                {props.languagesAvailable.map((language) => (

                                    <Select.Option key={language} value={language}>
                                        {t(`languages.${language}`)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col xs={24} md={12}>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button className="app-button" style={{ width: "95%" }} htmlType="submit" loading={loading} disabled={loading}>
                                {t("components.edit-users.btn-save")}
                            </Button>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button className="app-button" style={{ width: "95%" }} onClick={handleBtnCancel}>
                                {t("components.edit-users.btn-cancel")}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </div>
    );
};

export default EditUserForm;
