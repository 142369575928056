  // Enums

  
  export enum PaxTypeEnum {
    adult = 'adult',
    teenager = 'teenager',
    child = 'child',
    infant = 'infant',
  }
  
  export enum OfferSupplementTypeEnum {
    offer = 'offer',
    supplement = 'supplement',
  }
  
  export enum PaymentTypeEnum {
    fixed = 'fixed',
    percent = 'percent',
  }

  // Interfaces for other tables
  
 export interface BookingPax {
    id: string;
    paxType: PaxTypeEnum;
    age: number;
    name: string;
    surname: string;
    secondSurname?: string;
  }
  
  export default interface BookingData {
    // Inicio tabla booking_header
      id: string;
      proLocator: string; //  pro
      pmsLocator: string; // pms
      status: 'pre' | 'active' | 'cancelled';
      recordDate: Date;
      observations: string;

      isCancelled: boolean;
      lastUpdateDate?: Date;

      isNetPrice: boolean;

      currencyCode: string; // A través de la relación con la tabla de currencies
    // Fin tabla booking_header

      hotelInfo: HotelInfo; // Reference to HotelInfo
      ownerData: OwnerData; // Reference to OwnerData
      bookingLines: BookingRoom[]; // Collection of BookingLine
      extras: Extra[]; // Collection of Extra
      taxes: Tax[]; // Collection of Tax

      paymentMethodName  : string;

  }

 export interface CancellationPolicy{
    deadline : Date;
    penalty: number;
    penaltyType: PaymentTypeEnum;
  }

 export interface BookingRoom {
    
  //Inicio tabla booking_room
      id: string;
 
      name: string; // Es el nombre de la habitación en la tabla room
      code: string; // es el código de la habitación en la tabla room

      regimeName: string;
      regimeCode: string;

      rateCode: string;
      rateName: string;

      checkIn: Date;
      checkOut: Date;

      pvp: number;
      commission: number;
      net: number;

      totalAmount: number;
      totalCommissionAmount: number;

      ratekey: string;
    //Fin tabla booking_room
      mainImage: string; // Cogemos la imagen principal de la tabla booking_room_images
      paxes: BookingPax[]; // Collection of BookingPax
      taxes: Tax[]; // Collection of Tax
      cancellationPolicies: CancellationPolicy[]; // Collection of CancellationPolicy
  }
  
  
 export interface Extra {
    id: string;
   // extraId: string;
    extraCode: string;
    description: string;
    applicationDateFrom: Date;
    applicationDateTo: Date;
    quantity: number;
    price: number;
    priceBeforeTax: number;
    image: string;
  }
  
 export interface OwnerData {
    id: string;
    name: string;
    surname: string;
    secondSurname?: string;
    phone?: string;
    email: string;
  }
  
 
  
 export interface Tax {
    id: string;
    percent: number;
  }
  
 export interface HotelInfo{
    //Inicio tabla booking_hotel_info
      id: string;

      code: string;
      name: string;
      address: string;

      phone: string;
      email : string;
      director: string

      latitude: number,
      longitude: number,

      website: string,

      //Fin tabla booking_hotel_info

      mainImage: string, // Cogemos la imagen principal de la tabla booking_hotel_images

  }


 
  
  