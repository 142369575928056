import { Avatar, Button, Col, Dropdown, List, Menu, Rate, Row, Tabs, Tag, Tooltip, Typography } from 'antd';
import './ListCommunicationsComponent.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NewsData } from '../../../models/CommunicationsData';
import { URL_IMAGE, LanguageCodeToText, NewStatus, getCountryCode } from '../../../utils/constants';
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, MoreOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import TabPane from 'antd/es/tabs/TabPane';
import { useEffect, useState } from 'react';
import { EditFeaturedNews } from '../../../api/repositoryEP';
import { LoadingData } from '../../common';
import TagStatusNews from '../../news/TagStatusNews';
import moment from 'moment';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import ReactCountryFlag from 'react-country-flag';
import { useSessionData } from '../../../Contexts/SessionDataContext';

interface ListCommunicationsComponentProps {
    prefixImg: string;
    list: NewsData[];
    loading: boolean;
    setDeleteModalVisible: (isVisible: boolean) => void;
    setVisibleModalUnpublishedNews: (isVisible: boolean) => void;
    setIdSelected: (id: string) => void;
    changeNewStatus(id: string, isActive: boolean): void;
}

const ListCommunicationsComponent = (props: ListCommunicationsComponentProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const appConfig = useAppConfig();
    const userSesionData = useSessionData();
    const [newsList, setNewsList] = useState(props.list);


    const [selectedLanguages, setSelectedLanguages] = useState<Record<string, string>>({});

    useEffect(() => {
        setNewsList(props.list || []);
        const initialLanguages: Record<string, string> = {};
        props.list.forEach((news) => {
            if (news.titlesByLanguage.length > 0) {
                initialLanguages[news.id] = news.titlesByLanguage[0].key; // Idioma por defecto
            }
        });
        setSelectedLanguages(initialLanguages);
    }, [props.list]);

    const openDeleteModal = (id: string) => {
        if (id) {
            props.setIdSelected(id); // Configura el ID
            props.setDeleteModalVisible(true); // Abre el modal
        }
    };



    const openModalUnpublishedNews = (id?: string) => {
        if (id) {
            props.setIdSelected(id); // Configura el ID
            props.setVisibleModalUnpublishedNews(true); // Abre el modal
        }
    };

    const editNew = (id: string) => {
        navigate(`${window.location.pathname}/new/${id}`);
    };


    const seeNewsDetails = (id: string) => {
        navigate(`${window.location.pathname}/new-details/${id}`);
    };


    const changeNewStatus = (id: string, isActive: boolean) => {
        props.changeNewStatus(id, isActive);
    };




    const handleFeaturedNews = (id: string, isActive: boolean) => {
        EditFeaturedNews(id, isActive).then((response) => {
            if (response?.type == 1 && response.data == true) {
                setNewsList((prevList) =>
                    prevList.map((news) =>
                        news.id === id ? { ...news, isFeatured: isActive } : news
                    )
                );
            }
        });
    };


    const menu = (id: string, status: NewStatus) => (
        <Menu>
            <Menu.Item key="1" onClick={() => seeNewsDetails(id)} icon={<EyeOutlined />}>
                {t('components.communications.see')}
            </Menu.Item>
            <Menu.Item key="2" onClick={() => editNew(id)} icon={<EditOutlined />}>
                {t('components.communications.edit')}
            </Menu.Item>
            {status !== "active" && (
                <Menu.Item key="3" onClick={() => changeNewStatus(id, true)} icon={<CheckCircleOutlined />}>
                    {t('components.communications.publish')}
                </Menu.Item>
            )}
            {status === "active" && (
                <Menu.Item key="4" onClick={() => openModalUnpublishedNews(id)} icon={<CloseCircleOutlined />}>
                    {t('components.communications.unpublish')}
                </Menu.Item>
            )}
            <Menu.Item key="5" danger onClick={() => openDeleteModal(id)} icon={<DeleteOutlined />}>
                {t('components.communications.delete')}
            </Menu.Item>
        </Menu>
    );

    const ThreeDotsMenu = ({ id, status }: { id: string; status: NewStatus }) => (
        <Dropdown overlay={menu(id, status)} trigger={["click"]}>
            <Button type="text" icon={<MoreOutlined className="three-dots-icon" />} />
        </Dropdown>
    );




    const changeLanguage = (newsId: string, languageKey: string) => {
        setSelectedLanguages((prev) => ({ ...prev, [newsId]: languageKey }));
    };

    const renderButtons = (newsId: string, titlesByLanguage: { key: string; value: string }[]) => (
        <div className="language-buttons-container">
            {titlesByLanguage.map((kv) => (
                <Button
                    className='language-button'
                    key={kv.key}
                    type={selectedLanguages[newsId] === kv.key ? 'primary' : 'default'}
                    onClick={() => changeLanguage(newsId, kv.key)}
                >
                     <ReactCountryFlag countryCode={getCountryCode(kv.key)} svg /> {t(LanguageCodeToText.find((item) => item.key === kv.key)?.label || "")}
                </Button>
            ))}
        </div>
    );

    return (
        <div className="list-communications">
            {props.loading && <LoadingData />}
            {!props.loading &&
                newsList.map((f) => {
                    const currentLanguage = selectedLanguages[f.id] || f.titlesByLanguage[0]?.key;
                    const currentTitle = f.titlesByLanguage.find((kv) => kv.key === currentLanguage)?.value || "";

                    return (
                        <div key={f.id} className="news-item">


                            <Row gutter={0}>

                                <Col xs={24} md={8}>
                                    <img
                                        className="avatar-list-news"
                                        src={
                                            f.img
                                                ? `https://${props.prefixImg + f.img}?id=${Math.random()}`
                                                : `${URL_IMAGE}no-image.jpg`
                                        }
                                    />
                                </Col>
                                <Col xs={21} md={14} className='container-info-news'>
                                    <Row gutter={[22, 22]}>
                                        <Col xs={22} md={22}>
                                            {renderButtons(f.id, f.titlesByLanguage)}
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <div className="title">{currentTitle}</div>
                                        </Col>
                                        <Col xs={24} md={20} className="status-container">
                                            <div className="status-content">
                                                <TagStatusNews status={f.status} sheduleDayFrom={f.sheduleDayFrom} />
                                                {f.status === "active" && (
                                                    <div className="publish-info">
                                                        <Typography className="title-date">{t("components.communications.date")}</Typography>
                                                        <Typography className="date">                             
                                                            {moment.utc(f.sheduleDayFrom).local().format(appConfig.config.dateFormat)}
                                                        </Typography>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>




                                    </Row>
                                </Col>
                                <Col xs={3} md={2} className="actions-container">
                                    <Tooltip
                                        title={
                                            f.isFeatured
                                                ? t("components.communications.unfeature-news")
                                                : t("components.communications.feature-news")
                                        }
                                    >
                                        {f.isFeatured ? (
                                            <StarFilled onClick={() => handleFeaturedNews(f.id, false)} className="featured-icon" />
                                        ) : (
                                            <StarOutlined onClick={() => handleFeaturedNews(f.id, true)} className="featured-icon" />
                                        )}
                                    </Tooltip>
                                    <ThreeDotsMenu id={f.id} status={f.status} />
                                </Col>

                            </Row>
                        </div>
                    );
                })}
        </div>
    );
};

export default ListCommunicationsComponent;