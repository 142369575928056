import { Alert, Row } from "antd";
import { AccountAsignationData, EditAccountAsignationData, KeyValue } from "../../../models/AccountAsignationData";
import { LoadingData, NoData } from "../../common";
import AccountAsignationElement from "../AccountAsignationElement/AccountAsignationElement";
import { Contract } from "../../../models/Contract";

interface AccountAsignationListComponentProps {
    accountAsignationData: AccountAsignationData[];
    contracts: Contract[];
    markets: KeyValue[];
    edit: (data: EditAccountAsignationData) => void;
    delete: (id: string) => void;
    isLoading: boolean;
    setSelectedConfiguration: (aad: AccountAsignationData | null) => void;
    setEditDrawerVisible: (isVisible: boolean) => void;
}

const AccountAsignationListComponent: React.FC<AccountAsignationListComponentProps> = ({
    accountAsignationData,
    contracts,
    markets,
    edit,
    delete: handleDelete,
    isLoading,
    setSelectedConfiguration,
    setEditDrawerVisible,
}) => {
    if (isLoading) return <LoadingData />;

    if (!isLoading && accountAsignationData.length === 0) return <NoData />;

    return (
        <Row className="commissions-configuration-list">            
            {accountAsignationData.map((accountAsignation) => (
                <AccountAsignationElement
                    key={accountAsignation.id}
                    configuration={accountAsignation}
                    contracts={contracts}
                    markets={markets}
                    edit={edit}
                    delete={handleDelete}
                    setEditDrawerVisible={setEditDrawerVisible}
                    setSelectedConfiguration={setSelectedConfiguration}
                />
            ))}
        </Row>
    );
};

export default AccountAsignationListComponent;