import { Row } from "antd";
import { useTranslation } from "react-i18next";
import { EditPaymentConfigurationData, PaymentConfigurationData, PaymentMethodData } from "../../../models/PaymentMethodData";
import { KeyValue } from "../../../models/AccountAsignationData";
import { LoadingData, NoData } from "../../common";
import PaymentMethodElement from "../element/PaymentMethodElement";
import './PaymentsMethodList.scss';

interface PaymentsMethodListProps {
  configurations: PaymentConfigurationData[];
  markets: KeyValue[];
  paymentsMethods: PaymentMethodData[];
  save: (data: EditPaymentConfigurationData) => void;
  edit: (data: EditPaymentConfigurationData) => void;
  delete: (id: string) => void;
  isLoading: boolean;
  setSelectedConfiguration: (aad: PaymentConfigurationData | null) => void;
  setEditDrawerVisible: (isVisible: boolean) => void;
}

const PaymentsMethodList: React.FC<PaymentsMethodListProps> = ({
  configurations,
  markets,
  paymentsMethods,
  save,
  edit,
  delete: handleDelete,
  isLoading,
  setSelectedConfiguration,
  setEditDrawerVisible,
}) => {
    
  if (isLoading) return <LoadingData />;
      if (!isLoading && configurations.length === 0) return <NoData />;

  return (
    <Row className="payment-method-list">
      {configurations.map((pm) => (
        <PaymentMethodElement
          key={pm.id}
          configuration={pm}
          save={save}
          edit={edit}
          delete={handleDelete}
          setEditDrawerVisible={setEditDrawerVisible}
          setSelectedConfiguration={setSelectedConfiguration}
          markets={markets}
          paymentsMethods={paymentsMethods}
        />
      ))}
    </Row>
  );
};

export default PaymentsMethodList;
