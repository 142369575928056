import { useEffect, useState } from "react";
import {Contract} from "../../../models/Contract";
import moment from "moment";
import { useAppConfig } from "../../../Contexts/AppConfigContext";

import './ContractElementComponent.scss';
import { Button, Card, Dropdown, MenuProps, Switch, Tag, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined, StarTwoTone } from "@ant-design/icons";
import { GenericModal } from "../../common/Modal/GenericModal";
import HTMLReactParser from "html-react-parser";

interface ContractElementProps {
    onChangeContractStatus: (isEnabled: boolean, contractId: string) => void;
    onRemoveContract: (contractId: string) => void;
    onEditContract: (contractId: string) => void;
    onGetAssignedAccountsContract: (contractId: string) => void;
    contract?: Contract;
    showActions: boolean;
}

const ContractElementComponent = (props: ContractElementProps): JSX.Element => {
    const appConfig = useAppConfig();

    const { t } = useTranslation();

    const [showActions, setShowActions] = useState<boolean>(props.showActions);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showDeleteBaseModal, setShowDeleteBaseModal] = useState<boolean>(false);
    const [contract, setContract] = useState<Contract | undefined>(props?.contract);

    const onChangeStatus = (isEnabled: boolean) => {
        props.onChangeContractStatus(isEnabled, contract!.id);
    }

    const onEdit = () => {
        props.onEditContract(contract!.id);
    }

    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const onDelete = async () => {
        try{
            console.log("llegamos")
            setIsDeleting(true);
           await props.onRemoveContract(contract!.id);

        }finally{
            console.log("salimos")
            setIsDeleting(false);
        }
    }

    const showModal = () => {
        if (contract!.isBase) {
            setShowDeleteBaseModal(true);
        } else {
            setShowDeleteModal(true)
        }
    }

    const onGetAssignedAccountsContract = () => {
        props.onGetAssignedAccountsContract(contract!.id);
    }


    useEffect(() => {
        setContract(props.contract);
    }, [props.contract]);

    useEffect(() => {
        setShowActions(props.showActions);
    }, [props.showActions]);

    const cardTitle = <div className="contract-element__header">
        <div className="contract-element__header__name">
            {contract!.name}
            {
                contract!.isBase && <StarTwoTone className="star-is-base" twoToneColor={"orange"} style={{ fontSize: "18px" }} />
            }
            <small>
                {t('components.contracts.register-date')}: {moment(contract!.creationDate).format(appConfig.config.dateFormat)}
            </small>
        </div>
        {
            showActions && <div className="contract-element__header__actions">
                <Tooltip title={t('components.contracts.change-status')}>
                    <Switch disabled={contract!.isBase} loading={contract!.loading} defaultChecked={contract!.isActive} onChange={onChangeStatus} />
                </Tooltip>
                {
                    contract!.isBase && <div className="alert">
                        <small>
                            {t('components.contracts.a-base-contract-cant-disabled')}
                        </small>
                    </div>
                }
            </div>
        }

    </div>;

    return (
        <Card className="app-card" title={cardTitle} bordered={false}>
            <GenericModal
                title={t('components.contracts.remove-contract-title')}
                closable
                onCancel={() => { setShowDeleteBaseModal(false) }}
                visible={showDeleteBaseModal}
                isLoading={false}
                setModalVisible={() => {
                    setShowDeleteBaseModal(false);
                }}
                content={
                    <div>
                        <p>
                            {t('components.contracts.remove-contract-base-not-allowed')}
                        </p>
                    </div>
                }
            />

            <GenericModal
                title={t('components.contracts.remove-contract-title')}
                closable
                onCancel={() => { setShowDeleteModal(false) }}
                okDanger
                requiredTextConfirmation={contract!.name}
                onOk={ onDelete}
                visible={showDeleteModal}
                isLoading={isDeleting}
                setModalVisible={() => {
                    setShowDeleteModal(false);
                }}
                content={
                    <div>
                        <p>
                            {HTMLReactParser(t('components.contracts.remove-contract', [contract!.name, contract!.assignedAccounts]))}
                        </p>
                        <small>
                            {t('components.contracts.remove-contract-advise')}
                        </small>
                        <br /><br />
                    </div>
                }
            />

            <div className="contract-element">
                <div className="contract-element__body">
                    <div className="contract-element__body__item">
                        <label>
                            {t("components.contracts.pms-code")}
                        </label>
                        <span>{contract!.pmsCode.length > 0 ? contract!.pmsCode : 'N/D'}</span>
                    </div>
                    <div className="contract-element__body__item">
                        <label>
                            {t("components.contracts.integration-code")}
                        </label>
                        <span>{contract!.integrationCode}</span>
                    </div>
                    <div className="contract-element__body__item">
                        <label>
                            {t("components.contracts.is-base-contract")}
                        </label>
                        <span>
                            {contract!.isBase ? t("components.contracts.yes") : t("components.contracts.no")}
                        </span>
                    </div>

                    <div className="contract-element__body__item">
                        <label>
                            {t("components.contracts.assigned-accounts")}
                        </label>
                        <span className="app-link" onClick={onGetAssignedAccountsContract}>{contract!.assignedAccounts}</span>
                    </div>

                    {
                        showActions && <div className="contract-element__body__item actions">
                            <span>
                                <Button className="app-button" onClick={onEdit}><EditOutlined /></Button>
                                <Button disabled={contract!.isBase} className="app-button danger" onClick={showModal}><DeleteOutlined /></Button>
                            </span>
                        </div>
                    }


                </div>
                <div className="contract-element__body">
                    <div className="contract-element__body__item">
                        <label>
                            {t("components.contracts.hotels-affected")}
                        </label>
                        <span>
                            {contract?.hotelsAffected.map(hotel => {
                                return <Tag key={hotel.id} color="blue">{hotel.name}</Tag>
                            })}
                        </span>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default ContractElementComponent;