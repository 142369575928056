
import { Button, Divider, Dropdown, MenuProps, Space } from 'antd';
import './PrivateHeaderComponent.scss'
import { useTranslation } from 'react-i18next';
import { DownOutlined, EditOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, SmileOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { GetFormattedUrl } from '../../../utils/urls';
import { createAvatar } from '@dicebear/core';
import { identicon } from '@dicebear/collection';
import { useSessionData } from '../../../Contexts/SessionDataContext';
import HTMLReactParser from 'html-react-parser';
import { LanguageSelector } from '..';

interface PrivateHeaderComponentProps {
    toogleCollapse: () => void,
    doLogout: () => void
}

const PrivateHeaderComponent = (props: PrivateHeaderComponentProps) : JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const appConfig = useAppConfig();
    const { sessionData } = useSessionData();
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const { toogleCollapse, doLogout } = props;

    const onToogleCollapse = () : void => {
        setIsCollapsed(!isCollapsed);
        toogleCollapse();
    }

    const goDashboard = () : void => {
        navigate(GetFormattedUrl('private/dashboard'));
    }

    const onDoLogout = () : void => {
        doLogout();
    }

    const goProfile = () : void => {
        navigate(GetFormattedUrl('private/profile'));
    }

    const items: MenuProps['items'] = [
        {
          key: '1',
          label: (
            <div className="my-profile-link" onClick={goProfile}>
                <EditOutlined className="menu-icon"/> {t('components.private-header-component.my-profile-link')}
            </div>
          ),
        },
        {
            type: 'divider',
        },
        {
            key: '2',
            label: (
              <div className="close-session-link" onClick={onDoLogout}>
                    <LogoutOutlined className="menu-icon"/> {t('components.private-header-component.close-session-link')}
              </div>
            ),
          }
      ];

    const [currencies, setCurrencies] = useState<MenuProps['items']>([] as MenuProps['items']);
    
    const [avatarImage, setAvatarImage] = useState<string>('');

    useEffect(()=>{
        if (appConfig.config.availableLanguages){
            //<Select.Option disabled={!l.enabled} key={l.shortCode}>{l.description}</Select.Option>
            const availableCurrencies : MenuProps['items'] = appConfig.config.availableCurrencies.map((c, index) => {
                return {
                    key: `${c.iso}`,
                    label: (
                        <div className="language-item">
                            <span className="language-name">{c.iso}</span>
                        </div>
                    ),
                }
            });
            setCurrencies(availableCurrencies);
        }
    },[])

    useEffect(()=>{
        debugger;
        if (sessionData){
            const avatar = createAvatar(identicon, {
                seed: sessionData.userData.email,
                // ... other options
              });
              if (avatar){
                setAvatarImage(avatar.toString());
            }
        }else{
            console.log("ERROR: No se ha podido generar el avatar");
        }

        
    },[sessionData])
    

    return (
        <header className="private-header-component">
            <div className="logo">
                <div>
                    {
                        isCollapsed ? <MenuUnfoldOutlined onClick={onToogleCollapse} className="btn-collapse-menu app-icon"/> : <MenuFoldOutlined onClick={onToogleCollapse} className="btn-collapse-menu app-icon"/>
                    }
                </div>
                <img onClick={goDashboard} src='/images/beds2b-pro-logo-negative.png' />    
            </div>
            
            <div className="user-menu">
                {
                    appConfig.device !== "mobile"?
                        <div className="language-selector">
                            <LanguageSelector />
                        </div>
                    :
                    null
                }
                
                <div className="user-actions">
                    <div className="avatar">
                        <div className="avatar-image">
                            {
                                sessionData?.userData?.avatar ? <img src={sessionData?.userData?.avatar} /> : HTMLReactParser(avatarImage)
                            }
                        </div>
                    </div>
                    <span className="user-name">
                        <Dropdown menu={{ items, style:{borderRadius: "3px"} }} trigger={['click']}>
                            <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                {
                                    appConfig.device !== "mobile" ?
                                    <span className="name-container">
                                        { sessionData?.userData?.name } { sessionData?.userData?.surname } { sessionData?.userData?.secondSurname }
                                    </span>
                                    : null
                                }
                                <DownOutlined className="arrow-icon"/>
                            </Space>
                            </a>
                        </Dropdown>
                        
                    </span>
                </div>
            </div>
        </header>
    )
}

export default PrivateHeaderComponent;