import { Card, Col, Row, Switch, Spin, Alert, Button } from 'antd';
import { AccountUserData } from '../../../models/AccountUserData';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';  // Importa LoadingOutlined
import './AccountUsersListComponent.scss';
import { GetUserIcon } from '../../../utils/icons';
import moment from 'moment';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { LoadingData } from '../../common';
import { useEffect, useState } from 'react';

interface AccountUsersListComponentProps {
    list: AccountUserData[];
    onChangeUserStatus: (userId: string, status: string, oldStatus : string) => void;
    onResendConfirmationEmail: (userId: string) => void;
    loading: boolean;
}

const AccountUsersListComponent = (props: AccountUsersListComponentProps): JSX.Element => {

    const { list, loading } = props;
    const appConfig = useAppConfig();
    const { t } = useTranslation();
    useEffect(() => { console.log(list) }, [list]);


    const statusTranslated = (status: string) => {
        if(status == undefined || status == null){
            return "";
        }
        switch (status) {
            case 'active':
                return t('components.accounts.filter.status-active');
            case 'inactive':
                return t('components.accounts.filter.status-inactive');
            case 'validation_needed':
                return t('components.accounts.filter.status-validation-needed');
            case 'confirm_needed':
                return t('components.accounts.filter.status-confirm-needed');
            default:
                return "";
        }
    }

    const [isValidating, setIsValidating] = useState<boolean>(false);
    const [isResendingMsg, setIsResendingMsg] = useState<boolean>(false);



    // Condición para mostrar el icono de carga o el contenido
    if (loading) {
        return (
            <div className="loading-container" style={{ textAlign: 'center', padding: '20px' }}>
                <LoadingData />
            </div>
        );
    }


    return (
        <div className="account-users-list-component">
            {
                list.map((user: AccountUserData, index: number) => {
                    const cardHeader: JSX.Element = (
                        <div key={user.id} className="header-container">
                            <div className="resume-header">
                                &nbsp;
                            </div>
                            <div className="actions-container">

                                {( user.status && (user.status == 'active' || user.status == 'inactive')) && (
                                    <Switch
                                        className="app-switch"
                                        defaultChecked={user.status === 'active'}
                                        onChange={(isEnabled: any) => props.onChangeUserStatus(user.id, isEnabled ? 'active' : 'inactive', user.status)}
                                    />
                                )}

                                {user.status === 'validation_needed' && (
                                    <>
                                        {statusTranslated(user.status)}
                                        <Button
                                            className='app-button'
                                            loading={isValidating}
                                            disabled={isValidating}
                                            onClick={async () => {
                                                setIsValidating(true);
                                                await props.onChangeUserStatus(user.id, 'active',user.status); 
                                                setIsValidating(false);
                                            }}
                                        >
                                            {isValidating ? t('button.validating') : t('button.validate')}
                                        </Button>
                                    </>
                                )}
                                {user.status === 'confirm_needed' && (
                                    
                                    
                                    <>
                                        {statusTranslated(user.status)}
                                        <Button
                                            className='app-button'
                                            loading={isResendingMsg}
                                            disabled={isResendingMsg}
                                            onClick={async () => {
                                                setIsResendingMsg(true);
                                                await props.onResendConfirmationEmail(user.id); 
                                             setIsResendingMsg(false);
                                            }}
                                        >
                                          {isResendingMsg ? t('button.resending-confirmation') : t('button.resend-confirmation')}
                                        </Button>
                                    </>
                                    
                                    
                                    )}

                            </div>
                        </div>
                    );

                    return (
                        <Card className="app-card" title={cardHeader} bordered={true} key={user.id}>
                            <Row>
                                <Col className="info" xs={24}>
                                    <span>
                                        {GetUserIcon('app-icon width-18px')} <span>{user.name} {user.surname} {user.secondSurname}</span>
                                        <small>
                                            {t('components.account-users.list.creation-date', [moment(user.createdAt).format(appConfig.config.dateFormat)])}
                                        </small>
                                    </span>
                                    <span className="with-padding"> {user.email}</span>
                                    <span className="with-padding">{user.phone}</span>
                                </Col>
                            </Row>
                        </Card>
                    );
                })
            }

            {
                list.length === 0 && (
                    <Alert className="alert" message={t('components.account-users.list.empty')} type="info" />
                )
            }
        </div>
    );
};

export default AccountUsersListComponent;
