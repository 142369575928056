
import './ContractsPage.scss';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Input } from 'antd';
import PaginationComponent from '../../../components/Pagination/PaginationComponent';
import { useEffect, useState } from 'react';
import {Contract} from '../../../models/Contract';
import { changeContractStatus, createContract, deleteContract, fetchContracts, fetchHotels, updateContract } from '../../../api/repositoryEP';
import { LoadingData, NoData } from '../../../components/common';
import { AddContract, ContractElement, ContractList, EditContract } from '../../../components';
import { SearchOutlined } from '@ant-design/icons';
import CreateContract, { EditContractData } from '../../../models/CreateContract';
import { HotelInfo } from '../../../models/BookingData';

const ContractsPage = () : JSX.Element => {
    const { t } = useTranslation();

    const [showAddNewContract, setShowAddNewContract] = useState<boolean>(false);   
    const [showEditContract, setShowEditContract] = useState<boolean>(false);   

    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [defaultContract, setDefaultContract] = useState<Contract | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('');
    const [resetFieldIndex, setResetFieldIndex] = useState<number>(0);

    const getContracts = () => {

        setLoading(true);

        fetchContracts({f: filter, p: page, s: pageSize})
            .then((response) => {
                if (response && response.data && response.data.paginationData && response.data.paginationData.elements){
                    var resumeData = response.data.resume;
                    var theContracts = response.data.paginationData.elements as Contract[];
                    setContracts(theContracts);
                    //Search base contract
                    let baseContract = theContracts.find((contract: Contract) => contract.isBase);
                    if (baseContract){
                        baseContract.assignedAccounts = resumeData.totalAgenciesWithoutContract + 
                                                        resumeData.totalCompaniesWithoutContract + 
                                                        resumeData.totalEventsWithoutContract + 
                                                        resumeData.totalIndependentsWithoutContract;
                        setDefaultContract(baseContract);
                    }
                    
                    setTotalElements(response.data.paginationData.totalRecords);
                }else{
                    setContracts([]);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const [hotels, setHotels] = useState<HotelInfo[]>([]);
    const getHotels = () => {
        fetchHotels().then((response) => {
           if (response && response.data){
                setHotels(response.data as HotelInfo[]);
                console.log("los hoteles son los siguientes:" , response.data);
           }
        });
    }

    const [selectedContract, setSelectedContract] = useState<Contract>();
    const onShowEditContract = (contractId: string) => {
        
        let contract = contracts.find((contract: Contract) => contract.id === contractId)!;

        setSelectedContract(contract);
        setShowEditContract(true);

    };
const onRemoveContract = async (contractId: string): Promise<void> => {
    if (contractId) {
        let contractData = contracts.find((contract: Contract) => contract.id === contractId);

        if (contractData) {
            contractData.loading = true;
            setContracts([...contracts]);

            const response = await deleteContract(contractId); // ✅ Esto sí espera

            if (response?.typeToString === 'Success') {
                await getContracts(); // ✅ Asegurar que también espere antes de salir
            }
        }
    }
};

    

    const onGetAssignedAccountsContract = (contractId: string) => {
        console.log('Get assigned accounts contract', contractId);
    };

    const onChangeContractStatus = (isEnabled: boolean, contractId: string) => {
        let contractData = contracts.find((contract: Contract) => contract.id === contractId)!;
        if (contractData){
            // Para que se muestre el loading en el switch
            contractData.loading = true; 
            setContracts([...contracts]);               
            changeContractStatus(contractId, isEnabled)            
            .finally(() => {
                // Para que se quite el loading en el switch
                contractData.loading = false;
                setContracts([...contracts]);
            });
        }
        
    };

    const onPaginate = (p: number, s: number | undefined)=>{
        setPage(p);
        setPageSize(s ?? 10);
    }

    const [executing, setExecuting] = useState<boolean>(false);

    const onAddContract = (contractData: CreateContract) => {
        console.log("Contract Data", contractData);

        setExecuting(true);
        createContract(contractData).then((response) => {
            if (response?.typeToString == 'Success'){
                getContracts();
                setResetFieldIndex(resetFieldIndex + 1);
                setShowAddNewContract(false);
            }
        }).finally(() => {
            setExecuting(false);
        });


    }

    const onEditContract = (contractData: EditContractData) => {
        console.log("Contract Data", contractData);

        setExecuting(true);
        updateContract(contractData).then((response) => {
            if (response?.typeToString == 'Success'){
                getContracts();
                setShowEditContract(false);
            }
        }).finally(() => {
            setExecuting(false);
        });
        
    }

    useEffect(()=>{
        if (!showEditContract){
            setSelectedContract(undefined);
        }
    },[showEditContract])

    useEffect(()=> {
        //getContracts();
    }, [page, pageSize])

    useEffect(()=> {
        getContracts();
        getHotels();
    }, [])


    return(
        <div className="contracts-page">
            <Drawer title={t('pages.contracts.new-contract')} open={showAddNewContract} closable onClose={()=>{setResetFieldIndex(resetFieldIndex+1); setShowAddNewContract(false)}}>
                <AddContract hotels={hotels} loading={executing} resetFields={resetFieldIndex} onFinish={onAddContract} onCancel={()=>{setShowAddNewContract(false)}}/>
            </Drawer>

            <Drawer 
                title={t('pages.contracts.edit-contract')} 
                open={showEditContract && selectedContract != undefined} 
                closable 
                onClose={()=>{setShowEditContract(false)}}
            >
                <EditContract hotels={hotels} loading={executing} contractData={selectedContract} resetFields={resetFieldIndex} onFinish={onEditContract} onCancel={()=>{setShowEditContract(false);}}/>
            </Drawer>
            <h2 className="title">
                {t('pages.contracts.title')}
            </h2>
            <p>
                {t('pages.contracts.welcome-message')}
            </p>
            <div className="base-contract-info">
                <div className="app-logo">
                    <img src="/images/beds2b-pro-logo.png" alt="app-logo" />
                </div>
                <div className="contract-base-resume">
                    {
                        defaultContract && <ContractElement 
                                                key={999} 
                                                showActions={false}
                                                contract={defaultContract} 
                                                onChangeContractStatus={onChangeContractStatus}
                                                onRemoveContract={onRemoveContract}
                                                onEditContract={(contractId)=>{onShowEditContract(contractId);}}
                                                onGetAssignedAccountsContract={onGetAssignedAccountsContract}                                
                                                />
                    }
                    {
                        loading && !defaultContract && <LoadingData />
                    }
                </div>
            </div>
            <div className="contracts-list">
                <div className="contract-list-header">
                    <div className="contract-list-filter">
                        <Input
                            onClear={()=>setFilter('')}
                         onChange={(event)=>{
                            setFilter(event.target.value);
                        }} onPressEnter={getContracts} onBlur={getContracts} className="app-input" type='text' placeholder={t('pages.contracts.search')!} allowClear suffix={<SearchOutlined style={{cursor: "pointer"}}/>}/>
                    </div>
                    <div className="contract-list-actions">
                        <Button className="app-button" type="primary" onClick={()=>{                            
                            setShowAddNewContract(true)
                            }}>
                            {t('pages.contracts.new-contract')}
                        </Button>
                    </div>
                </div>
                <div className="contract-list-content">
                    {
                        loading ? 
                                    <LoadingData /> 
                                : 
                                    (
                                        contracts?.length == 0 ? 
                                                <NoData /> 
                                            :   <ContractList 
                                                    contracts={contracts}
                                                    onChangeContractStatus={onChangeContractStatus}
                                                    onRemoveContract={onRemoveContract}
                                                    onEditContract={onShowEditContract}
                                                    onGetAssignedAccountsContract={onGetAssignedAccountsContract} />
                                    )                       
                    }

                </div>
                <div className="contract-list-footer">
                    {
                        !loading && <PaginationComponent totalElements={totalElements} actualPage={page} actualPageSize={pageSize} onChange={onPaginate}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default ContractsPage;