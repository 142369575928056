import { Alert, Button, Checkbox, Col, Form, Input, Row, Select } from "antd";

import './EditContractComponent.scss';
import { useTranslation } from "react-i18next";
import { EditContractData } from "../../../models/CreateContract";
import { useEffect, useState } from "react";
import { Contract } from "../../../models/Contract";
import { HotelInfo } from "../../../models/BookingData";
import { set } from "lodash";

interface EditContractComponentProps {
    contractData: Contract | undefined;
    onFinish: (contractData: EditContractData) => void;
    onCancel: () => void;
    resetFields: number;
    loading: boolean;
    hotels: HotelInfo[];
}

const EditContractComponent = (props: EditContractComponentProps): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { onFinish, onCancel, resetFields } = props;
    const [contractData, setContractData] = useState<Contract | undefined>(props.contractData);
    const [loading, setLoading] = useState<boolean>(props.loading);
    const allHotelIds = props.hotels.map(h => h.id);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    useEffect(() => {
        console.log("hoteles seleccionados : ", props.contractData?.hotelsAffected.map(h => h.id));
        if (props.contractData) {
            setContractData(props.contractData);
            setIsActive(props.contractData.isActive ?? true);
            setIsBaseContract(props.contractData.isBase ?? false);
            setIsAllSelected(props.hotels.length === props.contractData.hotelsAffected.length);

            form.setFieldsValue({
                id: props.contractData.id,
                name: props.contractData.name,
                pmsCode: props.contractData.pmsCode,
                integrationCode: props.contractData.integrationCode,
                isBaseContract: props.contractData.isBase,
                isActive: props.contractData.isActive,
                hotelsAffected: props.contractData.hotelsAffected.map(h => h.id),
            });
        }
    }, [props.contractData]);





    useEffect(() => {
        form.resetFields();
        setIsAllSelected(false);
    }, [resetFields]);

    const onCancelEdit = (): void => {
        form.resetFields();
        onCancel();
    }





    const handleSelectChange = (value: string[]) => {
        if (value.includes("all") || value.length === allHotelIds.length) {
            setIsAllSelected(true);
            form.setFieldsValue({ hotelsAffected: allHotelIds });
        } else {
            setIsAllSelected(false);
            form.setFieldsValue({ hotelsAffected: value });
        }
    };
    


    const onEditContract = (): void => {

        if (contractData) {
            let contract: EditContractData = {
                id: contractData.id,
                name: form.getFieldValue('name'),
                pmsCode: form.getFieldValue('pmsCode'),
                integrationCode: form.getFieldValue('integrationCode'),
                isBase: isBaseContract,
                isActive: isActive,
                hotelsAffected: form.getFieldValue('hotelsAffected'),
            }

            onFinish(contract);
        }
    }

    const [isActive, setIsActive] = useState<boolean>(true);
    const [isBaseContract, setIsBaseContract] = useState<boolean>(false);

    useEffect(() => {
        if (isBaseContract) {
            form.setFieldsValue({ hotelsAffected: props.hotels.map(x => x.id) });
        }
    }, [isBaseContract]);

    return (
        <div className="add-contract">
            <Form form={form} layout="vertical" onFinish={onEditContract} >
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item
                            name="id"
                            hidden
                            initialValue={contractData?.id}
                        >
                            <Input className="app-input" allowClear />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            initialValue={contractData?.name}
                            label={t("components.contract-form-add.name")}
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input className="app-input" allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            initialValue={contractData?.pmsCode}
                            name="pmsCode"
                            label={t("components.contract-form-add.pms-code")}
                        >
                            <Input className="app-input" allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            name="integrationCode"
                            initialValue={contractData?.integrationCode}
                            label={t("components.contract-form-add.integration-code")}
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >

                            <Input className="app-input" allowClear />
                        </Form.Item>
                    </Col>


                    <Col xs={24}>
                        <Form.Item
                            name="isBaseContract"
                            label={t("components.contract-form-add.is-base-contract")}
                            initialValue={contractData?.isBase}
                        >
                            <Select disabled={contractData?.isBase} onChange={setIsBaseContract} className="app-input" allowClear>
                                <Select.Option value={true}>{t("components.contract-form-add.is-base-contract-yes")}</Select.Option>
                                <Select.Option value={false}>{t("components.contract-form-add.is-base-contract-no")}</Select.Option>
                            </Select>
                        </Form.Item>
                        {
                            isBaseContract && <Alert className="alert-base-message" message={t("components.contract-form-add.is-base-contract-message")} type="warning" />
                        }
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="isActive" label={t("components.contract-form-add.is-active")}
                            initialValue={isBaseContract || contractData?.isActive}>
                            <Select disabled={isBaseContract} onChange={setIsActive} className="app-input" allowClear value={isBaseContract || contractData?.isActive}>
                                <Select.Option value={true}>{t("components.contract-form-add.is-active-yes")}</Select.Option>
                                <Select.Option value={false}>{t("components.contract-form-add.is-active-no")}</Select.Option>
                            </Select>
                        </Form.Item>
                        {
                            isBaseContract && <Alert className="alert-base-message" message={t("components.contract-form-add.is-base-contract-then-active-mandatory-message")} type="warning" />
                        }
                    </Col>

                    <Col xs={24}>
                        <Form.Item name="hotelsAffected" initialValue={props.contractData?.hotelsAffected.map(x => x.id)} label={t("components.contract-form-add.hotels-affected")} rules={[{ required: true }]}>
                            <Select
                                disabled={isBaseContract}
                                allowClear
                                mode="multiple"
                                className="app-select"
                                filterOption={(input, option) =>  
                                    (option?.label as string)?.toLowerCase().includes(input.toLowerCase()) // Filtrado manual
                                }
                                onChange={handleSelectChange}
                                dropdownRender={menu => (
                                    <>
                                        <div style={{ padding: "8px", borderBottom: "1px solid #eee" }}>
                                            <Checkbox
                                                checked={isAllSelected}
                                                onChange={() => handleSelectChange(["all"])}
                                            >
                                                {t("components.contract-form-add.select-all-hotels")}
                                            </Checkbox>
                                        </div>
                                        {menu}
                                    </>
                                )}
                                options={props.hotels.map(hotel => ({
                                    label: hotel.name,
                                    value: hotel.id,
                                }))}
                            />
                        </Form.Item>

                    </Col>
                    <div className="actions">
                        <Button className="app-button danger" type="primary" disabled={loading} onClick={onCancelEdit}>
                            {t("components.contract-form-add.cancel")}
                        </Button>
                        <Button className="app-button" disabled={loading} loading={loading} type="primary" htmlType="submit">

                            {t("components.contract-form-add.save")}
                        </Button>
                    </div>
                </Row>
            </Form>
        </div>
    )
}

export default EditContractComponent;