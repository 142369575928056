import { Button, Col, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PaymentConfigurationData } from "../../../models/PaymentMethodData";
import { CheckIsValidPaymentConfigurationName } from "../../../api/repositoryEP";

interface PaymentsMethodFormProps {
    selectedConfiguration : PaymentConfigurationData | null;
    setSelectedConfiguration: (aad : PaymentConfigurationData | null) => void;
    create: (name: string) => void;
    editName: (id: string, name: string) => void;
    isLoading: boolean
}



const PaymentsMethodForm = (props: PaymentsMethodFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
     const [disableButton, setDisableButton] = useState<boolean>(true);
     

    const onFinish = async (values: { name: string }) => {
       
    try {
        if (props.selectedConfiguration) {
            await  props.editName(props.selectedConfiguration.id, values.name);
        } else {
            await  props.create(values.name);
        }
    } finally {
        form.setFieldValue("name", ""); // Restablecer el campo después de finalizar
    }}
    


    const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
    
    const checkIsValidName = (name: string): void => {
        // Limpia cualquier petición pendiente antes de iniciar una nueva
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
    
        // Configura un nuevo timeout para ejecutar la validación después de 500ms
        const timer = setTimeout(async () => {
            setDisableButton(true);

            form.setFields([{ name: "name", errors: [] }]);
    
            if (!name.trim()) {
                return;
            }
    
            if (
                props.selectedConfiguration &&
                props.selectedConfiguration.name.trim() === name.trim()
            ) {
                setDisableButton(false);
                return;
            }
    
            try {
                const response = await CheckIsValidPaymentConfigurationName(name);
                const isValid = response?.type === 1 ? response.data : false;
    
                if (!isValid) {
                    form.setFields([
                        {
                            name: "name",
                            errors: [t("pages.account-asignation.name-unavailable")],
                        },
                    ]);
                  //  setDisableButton(true);
                }else{
                    setDisableButton(false);
                }
            } catch (error) {

                form.setFields([
                    {
                        name: "name",
                        errors: [t("pages.account-asignation.api-error")],
                    },
                ]);
              
            }
    
            
        }, 300); // Espera 500ms antes de ejecutar la validación
    
        setDebounceTimer(timer);
    };
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        form.setFields([{ name: "name", errors: [] }]); // Limpia errores al escribir
        setDisableButton(true);
        checkIsValidName(value);
    };
    
    

    useEffect(() => {
        if (props.selectedConfiguration) {
            form.setFieldValue("name", props.selectedConfiguration.name);
        } else {
            form.resetFields();
        }
    }, [props.selectedConfiguration, form]); 






    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            initialValues={{ name: "" }} >

            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Form.Item
                        label={t("pages.account-asignation.name")}
                        name="name"
                        rules={[
                            { required: true, message: t("pages.account-asignation.name-required") || "" },
                        ]}
                    >
                        <Input onChange={handleInputChange}/>
                    </Form.Item>
                </Col>
                <Col xs={24} md={24} style={{ textAlign: "right" }}>
                    <Button loading={props.isLoading}
                        className="app-button"
                        disabled={props.isLoading || disableButton}
                        htmlType="submit">
                        {props.selectedConfiguration ? t("button.edit") : t("button.create")}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default PaymentsMethodForm;
