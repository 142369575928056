import { useTranslation } from 'react-i18next';
import { PublicFooter, PublicHeader } from '../../../../components/common';
import './TermnsAndConditionsPage.scss';
import HTMLReactParser from 'html-react-parser';

const TermnsAndConditionsPage = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="terms-and-conditions">
            
            <p className='bold'>
            {t('pages.terms-and-conditions.title')}
            </p>
           
            

            {/* Sección 1 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.1')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.1')}</p>
            <br></br>

            {/* Sección 2 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.2')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.2')}</p>
            <br></br>

            {/* Sección 3 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.3')}</p>
            <p>{t('pages.terms-and-conditions.subtitle-paragraph.3.1')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.3')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.4')}</p>
            <br></br>

            <p>{t('pages.terms-and-conditions.subtitle-paragraph.3.2')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.5')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.6')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.7')}</p>

            <br></br>

            <p>{t('pages.terms-and-conditions.subtitle-paragraph.3.3')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.8')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.9')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.10')}</p>
            <br></br>

            {/* Sección 4 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.4')}</p>
            <p>{t('pages.terms-and-conditions.subtitle-paragraph.4.1')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.11')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.12')}</p>
            <br></br>

            <p>{t('pages.terms-and-conditions.subtitle-paragraph.4.2')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.13')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.14')}</p>
            <br></br>

            {/* Sección 5 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.5')}</p>
            <p>{t('pages.terms-and-conditions.subtitle-paragraph.5.1')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.15')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.16')}</p>
            <br></br>

            <p>{t('pages.terms-and-conditions.subtitle-paragraph.5.2')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.17')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.18')}</p>
            <br></br>

            {/* Sección 6 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.6')}</p>
            <p>{t('pages.terms-and-conditions.subtitle-paragraph.6.1')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.19')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.20')}</p> 
            <br></br>

            <p>{t('pages.terms-and-conditions.subtitle-paragraph.6.2')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.21')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.22')}</p>
            <br></br>

            {/* Sección 7 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.7')}</p>
            <p>{t('pages.terms-and-conditions.subtitle-paragraph.7.1')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.23')}</p>
            <br></br>

            <p>{t('pages.terms-and-conditions.subtitle-paragraph.7.2')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.24')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.25')}</p>
            <br></br>

            {/* Sección 8 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.8')}</p>
            <p>{t('pages.terms-and-conditions.subtitle-paragraph.8.1')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.26')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.27')}</p>
            <br></br>

            {/* Sección 9 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.9')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.28')}</p>
            <br></br>

            {/* Sección 10 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.10')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.29')}</p>
            <p>{HTMLReactParser(t('pages.terms-and-conditions.paragraph.30'))}</p>
            <br></br>

  
        </div>
    );
}

export default TermnsAndConditionsPage;
