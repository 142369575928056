

import { Button, Card, Col, Collapse, Drawer, Row, Switch, Tag, Tooltip, Typography } from 'antd';
import AccountData from '../../../models/AccountData';
import './AccounstListComponent.scss'
import { GetAccountsIcon, getAccountTypeIcon } from '../../../utils/icons';
import moment from 'moment';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SmallCompanyData } from '../../../models/SmallCompanyData';
import { SmallAgencyData } from '../../../models/SmallAgencyData';
import AccountUsersPage from '../../../pages/Private/AccountUsersPage/AccountUsersPage';
import { useNavigate } from 'react-router-dom';
import { GetFormattedUrl } from '../../../utils/urls';
import { LoadingData } from '../../common';
import { AreaChartOutlined, MailOutlined, SignatureOutlined } from '@ant-design/icons';
import HTMLReactParser from 'html-react-parser';
import ValidateAccountForm from '../form/ValidateAccountForm';
import { KeyValue } from '../../../models/AccountAsignationData';
import './AccounstListComponent.scss'

interface AccountsListComponentProps {
    list: AccountData[];
    loading: boolean;
    companies: SmallCompanyData[];
    agencies: SmallAgencyData[];
    contracts: KeyValue[]
    onEditAccount: (id: string, isActive: boolean, typeAccount: string, contract: string | "") => Promise<void>;

}

const AccountsListComponent = (props: AccountsListComponentProps): JSX.Element => {
    const appConfig = useAppConfig();
    const { t } = useTranslation();
    const { list, loading, companies, agencies } = props;
    const navigate = useNavigate();
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false); // drawer of accounts
    const [selected, setSelected] = useState<AccountData>();
    const [validateAccountModalVisible, setValidateAccountModalVisible] = useState<boolean>(false);
    const [changingStatus, setChangingStatus] = useState<boolean>(false);




    const goBookings = (account: AccountData) => {
        navigate(GetFormattedUrl(`private/bookings-admin?type=${account.type}&account=${account.id}`))
    }


    const showEditDrawer = (item: AccountData) => {
        setSelected(item);
        setDrawerVisible(true);
    }
    const showValidateAccountDrawer = (item: AccountData) => {
        setSelected(item);
        setValidateAccountModalVisible(true);
    }

    useEffect(() => {
        console.log("Updated", companies, agencies)
    }, [agencies, props.agencies, companies, props.companies])



    const onEditAccount = async (id: string, isActive: boolean, type: string, contractId: string | ""): Promise<void> => {
        return await props.onEditAccount(id, isActive, type, contractId).then((r) => { });
    }


    useEffect(() => {
        console.log("cambiamos ", changingStatus)
    }, [changingStatus])

    return (
        <div className="accounts-list">
            <Drawer open={drawerVisible}
                title={<span>{selected?.commercialName}<small style={{ display: "block" }}>{selected?.address}</small></span>}
                onClose={() => { setDrawerVisible(false) }}
                width={"50%"}
                closable>
                <AccountUsersPage accountData={selected} />
            </Drawer>
            <Drawer open={validateAccountModalVisible}
                title={t('components.accounts.list.validate-account-title')}
                onClose={() => { setValidateAccountModalVisible(false) }}
                width={"50%"}
                closable
            >
                <ValidateAccountForm drawerVisible={validateAccountModalVisible}
                    setDrawerVisible={setValidateAccountModalVisible}
                    accountData={selected}
                    contracts={props.contracts}
                    onValidateAccount={onEditAccount} />
            </Drawer>
            {
                loading && <LoadingData />
            }

            {
                !loading && list.map((item: AccountData, index: number) => {
                    var cardHeader: JSX.Element = <div className="header-container" key={index}>
                        <div className="account-type">
                            <span>
                                <Tooltip title={t(`components.accounts.list.account-type-${item.type}`)}>
                                    {getAccountTypeIcon(item.type)}
                                </Tooltip>
                            </span>
                            <span>
                                {item.commercialName}
                                {item.creationDate && (
                                    <small>{t('components.accounts.list.creation-date')}: {moment(item.creationDate).format(appConfig.config.dateFormat)}</small>
                                )}

                            </span>

                        </div>
                        {(item.status == "active" || item.status == "inactive") ? (
                            <div className="actions-container">
                                <Switch
                                    className="app-switch"
                                    value={item.status === "active" ? true : false}
                                    defaultChecked={item.status === "active"}
                                    disabled={changingStatus && item.id === selected?.id}
                                    onChange={async (checked) => {
                                        setSelected(item);
                                        setChangingStatus(true); // Activa el estado antes de la operación
                                        try {
                                            await onEditAccount(item.id, checked, item.type, "").then((r) => { console.log(r) });

                                        } finally {
                                            setChangingStatus(false); // Desactiva el estado después de la operación
                                        }
                                    }}
                                />
                            </div>
                        )
                            :
                            (
                                <div className="button-validation-container">
                                    <Button
                                        className='app-button'
                                        onClick={() => showValidateAccountDrawer(item)}>
                                        {t('components.accounts.list.validate-account-button')}
                                    </Button>

                                </div>
                            )
                        }
                    </div>

                    return (
                        <Card className="app-card" title={cardHeader} bordered={false}>
                            <div className="body-container">

                                <Row align="top"gutter={[10, 20]}>
                                    <Col xs={2} sm={1} >
                                        {GetAccountsIcon('app-icon width-14px')}
                                    </Col>
                                    <Col xs={22} sm={5} >
                                        <Row>{item.manager && <span>{item.manager}</span>}</Row>
                                        <Row> {item.address && <span>{item.address}</span>}</Row>
                                    </Col>

                                    <Col xs={2} sm={1}>
                                        <AreaChartOutlined className='app-icon width-14px' />
                                    </Col>
                                    <Col xs={22} sm={5} >

                                        {HTMLReactParser(t('components.accounts.list.statistics.title'))}:
                                        <Row gutter={[0, 0]}>
                                            <Col xs={24} md={24}><strong>{t('components.accounts.list.statistics.requests')}</strong>: {item.totalAccountRequests ?? 0} </Col>
                                            <Col xs={24} md={24}><strong>{t('components.accounts.list.statistics.active-bookings')}</strong>: {item.activeBookingCounter ?? 0} </Col>
                                            <Col xs={24} md={24}><strong>L2B</strong>: {item.conversionRatio ?? 0}%</Col>
                                        </Row>
                                    </Col>
                                    <Col xs={2} sm={1}>
                                        <MailOutlined className="app-icon width-14px" />
                                    </Col>
                                    <Col xs={22} sm={5}>
                                        {item.contact?.email && (
                                            <Row >
                                                <Col md={24} style={{ marginRight: 5 }} >
                                                    <strong >{t('components.accounts.list.email')}</strong>
                                                </Col>
                                                <Col md={24}>
                                                    <a href={`mailto:${item.contact.email}`} title={item.contact.email} className="email-truncated">
                                                        {item.contact.email}
                                                    </a>
                                                </Col>
                                            </Row>
                                        )}
                                        {item.contact?.phone && (
                                            <Row>
                                                <span>
                                                    <strong>{t('components.accounts.list.phone')}</strong>: {item.contact.phone}
                                                </span>
                                            </Row>
                                        )}
                                    </Col>
                                    <Col xs={2} sm={1} ></Col>
                                    <Col xs={22} sm={5} >
                                        {item.resume?.usersNumber !== undefined && (
                                            <Row style={{ flex: "flex-end" }}>
                                                <span
                                                    onClick={item.resume.usersNumber > 0 ? () => showEditDrawer(item) : undefined} // Solo se ejecuta si usersNumber es mayor que 0
                                                    style={{ cursor: item.resume.usersNumber > 0 ? "pointer" : "default" }} // Cambia el cursor dependiendo del valor
                                                >
                                                    <strong>{t('components.accounts.list.number-of-users')}  </strong>:
                                                    <span
                                                        className="app-link"
                                                        style={{
                                                            textDecoration: item.resume.usersNumber > 0 ? "underline" : "none", // Solo subraya si el valor es mayor que 0
                                                            color: item.resume.usersNumber > 0 ? "blue" : "black" // Cambia el color para indicar si es clicable
                                                        }}
                                                    >
                                                        {item.resume.usersNumber}
                                                    </span>
                                                </span>
                                            </Row>
                                        )}

                                        {item.resume?.bookingNumber !== undefined && (
                                            <Row style={{ marginRight: 0 }}>
                                                <span
                                                    onClick={item.resume.bookingNumber > 0 ? () => goBookings(item) : undefined} // Solo se ejecuta si bookingNumber es mayor que 0
                                                    style={{ cursor: item.resume.bookingNumber > 0 ? "pointer" : "default" }} // Cambia el cursor dependiendo del valor
                                                >
                                                    <strong>{t('components.accounts.list.number-of-bookings')}  </strong>:
                                                    <span
                                                        className="app-link"
                                                        style={{
                                                            textDecoration: item.resume.bookingNumber > 0 ? "underline" : "none", // Solo subraya si el valor es mayor que 0
                                                            color: item.resume.bookingNumber > 0 ? "blue" : "black" // Cambia el color para indicar si es clicable
                                                        }}
                                                    >
                                                        {item.resume.bookingNumber}
                                                    </span>
                                                </span>
                                            </Row>
                                        )}
                                    </Col>

                                    <Col xs={2} sm={1} style={{ marginTop: 10 }}>
                                        <SignatureOutlined className='app-icon width-14px' />
                                    </Col>
                                    <Col xs={22} sm={22}>
                                        <Collapse accordion>
                                            {item.contracts.map((contract, index) => (
                                                <Collapse.Panel
                                                    key={index}
                                                    header={
                                                        <span className='contract-container'>
                                                            {contract.name}
                                                        </span>
                                                    }
                                                >
                                                    {contract.hotelsAffected.length > 0 ? (
                                                        <div className="hotels-affected-container" >
                                                            {contract.hotelsAffected.map((hotel, hIndex) => (
                                                                <Tag color="blue" key={hIndex} className='hotels-tags'>
                                                                    {hotel.name}
                                                                </Tag>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <Typography.Text type="secondary">{t("alert.no-hotels-associated")}</Typography.Text>
                                                    )}
                                                </Collapse.Panel>
                                            ))}
                                        </Collapse>
                                    </Col>
                                </Row>
                            </div>
                        </Card>

                    )
                })
            }
        </div>
    )
}

export default AccountsListComponent;