import { Card, Switch, Drawer } from 'antd';
import './UsersListComponent.scss';
import moment from 'moment';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { changeAccountUserStatus, fetchLanguagesAvailables } from '../../../api/repositoryEP';
import ReactCountryFlag from "react-country-flag"
import { EditOutlined } from '@ant-design/icons';
import EditUserForm from '../../EditUserForm/EditUserForm';
import { UserData } from '../../../models/UserData';
import { UsersFilterData2 } from '../../../models/UsersFilterData';
import { LoadingData } from '../../common';

interface UsersListComponentProps {
    list: UserData[];
    loading: boolean;
    onSearch: (filterData: UsersFilterData2) => void;

}

const UserListComponent = (props: UsersListComponentProps): JSX.Element => {
    const appConfig = useAppConfig();
    const { t } = useTranslation();
    const { list, loading } = props;
    const screenWidth = window.innerWidth;
    const drawerWidth = screenWidth <= 768 ? '100%' : '40%';
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [languagesInWA,setLanguagesInWA] = useState<string[]>([]);

    useEffect(() => {
      fetchLanguagesAvailables("bo").then((response) => {
          if (response?.type === 1) {
            setLanguagesInWA(response.data);
          }
      });
  }, []);
    const initialUserData: UserData = {
        id: '',
        username: '',
        name: '',
        surname: '',
        secondSurname: '',
        email: '',
        phone: '',
        role: '',
        languaje: '',
        creationDate: new Date(),
        isActive: false,
        confirmNeeded: false,
        permissions: []
    };

    const [selectedUser, setSelectedUser] = useState<UserData>(initialUserData);


    const getCountryCode = (language: string): string => {
        switch (language) {
            case "es":
            case "es-ES":
                return "es";
            case "fr":
            case "fr-FR":
                return "fr";
            case "pt-PT":
                return "pt";
            case "pt-BR":
                return "br"
            case "en-US":
                return "us";
            case "es-MX":
                return "MX";
            default:
                return "gb"; // Establece en inglés por defecto
        }
    };

    const getLanguage = (language: string): string => {
        return t(`languages.${language}`, { language: language });
    };

    const handleEdition = (user: UserData) => {
        console.log("el usuaior es : " , user)
        setSelectedUser(user);
        setDrawerVisible(true);
    };

    const getRole = (role: string): string => {
        return t(`roles.${role}`, { role: role });
    };

    useEffect(() => {
        if (!drawerVisible) {
            const c: UsersFilterData2 = {
                name: "",
                email: "",
                username: "",
                state: "",
                role: "",
                page: 0,
                pageSize: 10
            }
            props.onSearch(c)
        }
    }, [drawerVisible]);

    const changeUserStatus = (id: string, isActive: boolean) => {
        changeAccountUserStatus(id, isActive ? 'active' : 'inactive', isActive ? 'inactive' : 'active')
    };


    return (
        <div className="accounts-list">
            {loading && <LoadingData />}

            {!loading &&
                list.map((item: UserData, index: number) => {
                    var cardHeader: JSX.Element = (
                        <div className="header-container" key={index}>
                            <div className="account-type">
                                <span></span>
                                <span>
                                    {item.username} <EditOutlined style={{ fontSize: '20px' }} onClick={() => handleEdition(item)} />
                                    <small>{item.name} {item.surname} {item.secondSurname}</small>
                                </span>
                            </div>
                            <div className="actions-container">
                                {item.confirmNeeded && (
                                    <div className="confirm-status">
                                        {t("components.edit-users.no-confirmed")}
                                    </div>
                                )}
                                <Switch
                                    className="app-switch"
                                    defaultChecked={item.isActive}
                                    disabled={item.confirmNeeded === true}
                                    onChange={(checked) => changeUserStatus(item.id, checked)}
                                />
                            </div>
                        </div>
                    );

                    return (
                        <Card className="app-card" title={cardHeader} bordered={false} key={index}>
                            <div className="body-container">
                                <div className="additional-info">
                                    <div className="names">
                                        <span>{getRole(item.role)}</span>
                                        <span>
                                            <ReactCountryFlag countryCode={getCountryCode(item.languaje)} svg /> {' '}
                                            {getLanguage(item.languaje)}
                                        </span>
                                    </div>
                                    <div className="contact">
                                        <span>
                                            <strong>{t('components.accounts.list.email')}</strong>: {item.email}
                                        </span>
                                        <span>
                                            <strong>{t('components.accounts.list.phone')}</strong>: {item.phone}
                                        </span>
                                    </div>
                                    <span><strong>{t('components.accounts.list.creation-date')} </strong>: {moment(item.creationDate).format(appConfig.config.dateFormat)}</span>
                                </div>
                            </div>
                        </Card>
                    );
                })
            }

            {/* Drawer separado del bucle */}
            <Drawer open={drawerVisible} title={<span>{t("components.edit-users.title")}</span>} onClose={() => setDrawerVisible(false)} width={drawerWidth} closable>
                {selectedUser && (
                    <EditUserForm
                        setDrawerVisible={setDrawerVisible}
                        user={selectedUser}
                        userId={selectedUser.id}
                        languagesAvailable={languagesInWA}
                    />
                )}
            </Drawer>
        </div>
    );
};

export default UserListComponent;