import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'CommissionConfiguration'

export const CommissionConfigurationEP : EndPointConfig[] =[
     
        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("CreateCommissionConfiguration"),

        new EndPointConfig()
        .setMethod("DELETE")
        .setController(controller)
        .setActionName("DeleteCommissionConfiguration"),


        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetCommisionConfiguration"),

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("EditCommissionConfiguration"),

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("CheckConfigurationAccountConflict"),

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetConfigurationAccounts"),

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("CalculateAffectedAccounts"),


        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("CheckIsValidCommissionConfigurationName"),

        

        


        
]