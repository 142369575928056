import { Select, Skeleton } from 'antd'
import { useEffect, useState } from 'react';
import { Language } from '../../../models/Language';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { DEFAULT_LANGUAGE } from '../../../i18n/i18n';
import { GetAppLanguage, UpdateAppLanguage } from '../../../RoutesApp';

import './LanguageSelectorComponent.scss';
import { GetArrowSelectIcon } from '../../../utils/icons';
import { t } from 'i18next';

const LanguageSelectorComponent = () : JSX.Element =>{

    const appConfig = useAppConfig();
    const navigationLanguage = GetAppLanguage();

    const [availableClientLanguages, setAvailableClientLanguages] = useState<Language[]>([]);

    const getAvailableClientLanguages = () : void => {
        if (appConfig && appConfig.config.availableLanguages){
            setAvailableClientLanguages(appConfig.config.availableLanguages);
        }
    }

    const changeLanguage = (shortCode: string) : void => {
        if (shortCode && shortCode.length > 0){
            UpdateAppLanguage(shortCode);
        }else{
            UpdateAppLanguage(DEFAULT_LANGUAGE);
        }
    }

    const translate = (key: string) : string => {
        const c = "languages"
        return t(`${c}.${key}`);
    }

    useEffect(()=>{
        getAvailableClientLanguages();
    },[appConfig])

    return (
        <>
            {
                availableClientLanguages && availableClientLanguages.length > 0
                ?
                    <Select className='language-selector' defaultValue={translate(navigationLanguage)} onChange={changeLanguage} suffixIcon={GetArrowSelectIcon()}>
                        {
                            availableClientLanguages.map((l: Language) => {
                                return <Select.Option disabled={!l.enabled} key={l.shortCode}>{l.description}</Select.Option>
                            })
                        }
                    </Select>
                :
                    <Skeleton.Input active size={'small'} />
            }
        </>
    )
}

export default LanguageSelectorComponent;