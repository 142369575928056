import { useTranslation } from 'react-i18next';
import './AccountAsignationPage.scss';
import { Alert, Button, Col, Drawer, notification, Row } from 'antd';
import { useEffect, useState } from 'react';
import { AccountAsignationData, ConfigurationsFilter, EditAccountAsignationData, KeyValue } from '../../../models/AccountAsignationData';
import AccountAsignationForm from '../../../components/commissions/AccountAsignationForm/AccountAsignationForm';
import { AccountAsignationFilter, AccountAsignationList, CustomPagination } from '../../../components';
import { CreateCommissionConfiguration, DeleteCommissionConfiguration, EditCommissionConfiguration, fetchAccounts, GetAllContracts, GetCommisionConfiguration, GetCountries } from '../../../api/repositoryEP';
import { useSessionData } from '../../../Contexts/SessionDataContext';
import { PageSize } from '../../../utils/constants';
import { Contract } from '../../../models/Contract';



const AccountAsignationPage = (): JSX.Element => {
    const [commissionConfigurationList, setCommissionConfigurationList] = useState<AccountAsignationData[]>([]);
    const [selectedConfiguration, setSelectedConfiguration] = useState<AccountAsignationData | null>(null);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [executing, setIsExecuting] = useState<boolean>(false);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
    const { t } = useTranslation();
    const { sessionData } = useSessionData();

    const [actualFilter, setActualFilter] = useState<ConfigurationsFilter>({ name: "", page: 0, pageSize: PageSize });
    const [totalRecords, setTotalRecords] = useState<number>(0)
    const [markets, setMarkets] = useState<KeyValue[]>([]);
    const [contracts, setContracts] = useState<Contract[]>([]);


    useEffect(() => {
        const fetchCommisionConfiguration = async () => {
            setIsLoadingData(true);
            await getCommissionsConfiguration();
            setIsLoadingData(false);
        };

        fetchCommisionConfiguration();
    }, [actualFilter]);


    const getMarkets = async () => {
        const r = await GetCountries();
        if (r?.type === 1) {
            setMarkets(r.data as KeyValue[]);
        }
    };

    const getContracts = async () => {
        const r = await GetAllContracts();
        if (r?.type === 1) {
            setContracts(r.data as Contract[]);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            setIsLoadingData(true);
            try {
                await Promise.all([getContracts(), getMarkets(), getCommissionsConfiguration()]);
            } finally {
                setIsLoadingData(false);
            }
        };

        loadData();
    }, []);






    const getCommissionsConfiguration = async () => {

        const r = await GetCommisionConfiguration(actualFilter);
        if (r?.type === 1) {
            setCommissionConfigurationList(r.data.configurations || []);
            setTotalRecords(r.data.totalRecords || 0);           
        } else {
            setCommissionConfigurationList([]);
            setTotalRecords(0);
        }

    };

    
    const getCommissionsConfigurationWithNotification = async () => {

        const r = await GetCommisionConfiguration(actualFilter);
        if (r?.type === 1) {
            setCommissionConfigurationList(r.data.configurations || []);
            setTotalRecords(r.data.totalRecords || 0);
              notification.success({
                    message: t("notification.success"),
                    description: t('notification.success-description')
                  });
        } else {
            setCommissionConfigurationList([]);
            setTotalRecords(0);
        }

    };





    const onEdit = async (data: EditAccountAsignationData): Promise<void> => {
        setIsExecuting(true);
        try {
            const response = await EditCommissionConfiguration(data); 
            if(response?.type === 1 ){
                await getCommissionsConfigurationWithNotification();    // aunque vaya mal me da igual l porque quiero que me actualice siempre las configuraciones 
            }

        } finally {
            setIsExecuting(false);
        }
    };
    useEffect(() => { }, [commissionConfigurationList]);


    const onDelete = (id: string): Promise<void> => {
        return DeleteCommissionConfiguration(id).then((r) => {
            if (r?.type === 1 && commissionConfigurationList) {
                setCommissionConfigurationList(commissionConfigurationList.filter((c) => c.id !== id));
                setTotalRecords(totalRecords - 1);
            }
        });
    };

    const onCreate = (name: string): Promise<void> => {
        setIsExecuting(true);
        return CreateCommissionConfiguration(sessionData.userData.id, name)
            .then((r) => {
                if (r?.type === 1) {
                    setActualFilter({
                        name: "",
                        page: actualFilter.page,
                        pageSize: actualFilter.pageSize,
                    });
                }
            })
            .finally(() => {
                setIsExecuting(false);
                setSelectedConfiguration(null);
                setDrawerVisible(false);
            });
    };

    const onEditName = (id: string, name: string): Promise<void> => {
        setIsExecuting(true);

        const c: EditAccountAsignationData = {
            id: id,
            name: name,
            status: null,
            applyFrom: null,
            applyTo: null,
            marketsSelected: null,
            accountsSelected: null,
            contractSelected: null,
        };

        return EditCommissionConfiguration(c)
            .then((r) => {
                if (r?.type === 1) {
                    setCommissionConfigurationList(
                        commissionConfigurationList.map((config) =>
                            config.id === id ? { ...config, name: name } : config
                        )
                    );
                }
            })
            .finally(() => {
                setIsExecuting(false);
                setSelectedConfiguration(null);
                setDrawerVisible(false);
            });
    };



    const [actualPage, setActualPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(PageSize);

    const onChangePagination = (newPage: number, newPageSize?: number | undefined) => {
        setActualPage(newPage);
        setPageSize(newPageSize ?? pageSize);
        setActualFilter({
            ...actualFilter,
            page: newPage - 1,
            pageSize: newPageSize ?? pageSize
        });
    }


    return (
        <Row className="account-asignation-page" gutter={[20, 20]}>
            <Drawer open={drawerVisible}
                title={t("pages.account-asignation.title-create-commission")}
                onClose={() => { setSelectedConfiguration(null); setDrawerVisible(false); }}
                width={"50%"}
                closable>
                <AccountAsignationForm
                    isLoading={executing}
                    create={onCreate}
                    editName={onEditName}
                    selectedConfiguration={selectedConfiguration}
                    setSelectedConfiguration={setSelectedConfiguration}

                />
            </Drawer>
            <Col xs={24} md={24}>
                <h2 className="title">
                    {t('pages.account-asignation.title')}
                </h2>
            </Col>
            <Col xs={24} md={24}>
                <p>
                    {t('pages.account-asignation.welcome-message')}
                </p>
            </Col>
            <Col xs={24} md={14}>
                <AccountAsignationFilter actualFilter={actualFilter} setActualFilter={setActualFilter} />
            </Col>
            <Col xs={24} md={10} className='button-container'>
                <Button
                    className="app-button"
                    disabled={isLoadingData}
                    onClick={() => {
                        setSelectedConfiguration(null);
                        setDrawerVisible(true);
                    }}
                >
                    + {t('pages.account-asignation.create-commission')}
                </Button>

            </Col>
            <Col xs={24} md={24}>

            <Alert message={t("alert.commission-configuration")} type="warning" />
            </Col>

            <Col xs={24} md={24}>

                <AccountAsignationList
                    accountAsignationData={commissionConfigurationList}
                    markets={markets}
                    contracts={contracts}
                    edit={onEdit}
                    delete={onDelete}
                    setSelectedConfiguration={setSelectedConfiguration}
                    isLoading={isLoadingData}
                    setEditDrawerVisible={setDrawerVisible} />
            </Col>
            <div className="footer">
                {
                    commissionConfigurationList?.length > 0 && !isLoadingData && <div className="pagination-container">
                        <CustomPagination actualPage={actualPage} actualPageSize={pageSize} totalElements={totalRecords} onChange={onChangePagination} />
                    </div>
                }
            </div>
        </Row>
    )
}

export default AccountAsignationPage;