import { useTranslation } from 'react-i18next';
import { AccountsFilter, AccountsList, CustomPagination } from '../../../components';

import {AccountFilter} from '../../../models/AccountsFilter';

import './AccountsPage.scss';
import { editAccount, fetchAccounts, GetAllContracts } from '../../../api/repositoryEP';
import { useEffect, useState } from 'react';
import AccountData from '../../../models/AccountData';
import { SmallCompanyData } from '../../../models/SmallCompanyData';
import { SmallAgencyData } from '../../../models/SmallAgencyData';
import { NoData } from '../../../components/common';
import { KeyValue } from '../../../models/CommunicationsData';

const AccountsPage = () : JSX.Element => {
    const defaultPageSize = 10;

    const [actualPage, setActualPage] = useState<number>(1);
    const [actualPageSize, setActualPageSize] = useState<number>(defaultPageSize);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [actualFilter, setActualFilter] = useState<AccountFilter>({
        name: '',
        email: '',
        status: '',
        location: '',
        accountType: '',
        page: 0,
        pageSize: defaultPageSize,
    
    });

    const [companies, setCompanies] = useState<SmallCompanyData[]>([]);
    const [agencies, setAgencies] = useState<SmallAgencyData[]>([]);
    const [contracts,setContracts] = useState<KeyValue[]>([]);


    const onChangePagination = (newPage: number, newPageSize?: number | undefined) => {
        setActualPage(newPage);
        setActualPageSize(newPageSize ?? defaultPageSize);
        setActualFilter({
            ...actualFilter,
            page: newPage - 1,
            pageSize: newPageSize ?? defaultPageSize
        });
    }

    const [accountList, setAccountList] = useState<AccountData[]>([]);
    const [searching, setSearching] = useState<boolean>(false);
    const onSearch = () => {
        setSearching(true);
        fetchAccounts(actualFilter).then((r)=>{
            if (r && r.type === 1 ){
                setAccountList(r.data.elements as AccountData[]);
                setTotalRecords(r.data.totalRecords as number);
            }else{
                setAccountList([]);
                setTotalRecords(0);
            }
            setSearching(false);
        })
    }

    const onSearchInTheBackground = () => {

        fetchAccounts(actualFilter).then((r)=>{
            if (r && r.type === 1 ){
                setAccountList(r.data.elements as AccountData[]);
                console.log("tenemos lo siguietne : ", r.data.elements)
                setTotalRecords(r.data.totalRecords as number);
            }else{
                setAccountList([]);
                setTotalRecords(0);
            }

        })
    }

    useEffect(()=>{
        onSearch();
    },[actualFilter]);

    useEffect(()=>{
        GetAllContracts().then((r)=>{
            if(r && r.type == 1 && r.data){
                setContracts(r.data as KeyValue[]);
            }
        })
    },[]);

    const onEditAccount =  async (id: string, isActive: boolean, type: string, contractId: string): Promise<void> => {
       await  editAccount(id, isActive, type, contractId).then((r)=>{
            if(r && r.type === 1){
                onSearchInTheBackground();
                console.log("llega a buscar y me cambia todo")
            }
        });
    }; 

    return(
        <div className="accounts-page">
            <div className="filter-container">
                <AccountsFilter onSearch={setActualFilter} actualFilter={actualFilter} searching={searching} />
            </div>
            {
                !searching && accountList.length === 0 ? 
                    <NoData />
                :
                <div>
                    <div className="list-container">
                        <AccountsList list={accountList} loading={searching} 
                                    agencies={agencies}
                                    companies={companies}
                                    contracts={contracts}
                                    onEditAccount={onEditAccount}
                        />
                    </div>
                    {
                        !searching && <div className="pagination-container">
                                            <CustomPagination totalElements={totalRecords} actualPage={actualPage} actualPageSize={actualPageSize} onChange={onChangePagination}/>
                                        </div>  
                    }  
                </div>
            }
        </div>
    )
}

export default AccountsPage;