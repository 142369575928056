import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row, Select } from "antd";

interface AccountUsersFilterProps {
  onSearch: (values: any) => void;  // Asegurarse de que acepta una función para el evento de búsqueda
  searching: boolean;
}

const AccountUsersFilterComponent = (props: AccountUsersFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const { searching, onSearch } = props;

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    onSearch(values);  
  };


  const statusOptions = [
    { value: '', label: t('components.accounts.filter.any-status') },
    { value: 'active', label: t('components.accounts.filter.status-active') },
    { value: 'inactive', label: t('components.accounts.filter.status-inactive') },
    { value: 'confirm_needed', label: t('components.accounts.filter.status-confirm-needed') },
    { value: 'validation_needed', label: t('components.accounts.filter.status-validation-needed') }
  ];

  return (
    <div className="account-users-filter">
      <Form form={form} onFinish={onFinish} layout="vertical">  {/* Importante: onFinish asignado correctamente */}
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="name"
              label={t("components.account-users.filter.name-and-surname")}
            >
              <Input className="app-input" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="email"
              label={t("components.account-users.filter.email")}
            >
              <Input className="app-input" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="status"
              label={t('components.account-users.filter.status')}
            >
              <Select
                className="app-select"
                placeholder={t('components.account-users.filter.status-select')}
                defaultValue=""
              >
                {statusOptions.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} style={{ marginTop: 30 }}>
            <Form.Item>
              <Button className="app-button" style={{ width: "100%" }} htmlType="submit" disabled={searching} loading={searching}>
                {searching ? t("button.searching") : t("button.search")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      
    </div>
  );
};

export default AccountUsersFilterComponent;
