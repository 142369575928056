import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Input, Row, Select, Drawer, Checkbox } from "antd";
import "./NewUserForm.scss";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import { createUser, isValidUsername } from "../../api/repositoryEP";
import { Dispatch, SetStateAction } from 'react';
import NewUserData from "../../models/NewUserData";
import { useSessionData } from "../../Contexts/SessionDataContext";
import UserSessionData from "../../models/UserSessionData";
import { permissionBoBasic, permissionBoManager, permissionsSuperAdmin, sectionOptions, Sections } from "../../utils/constants";


interface NewUserFormProps {
    setDrawerVisible: Dispatch<SetStateAction<boolean>>;
    languagesAvailable: string[];

}

const NewUserForm = (props: NewUserFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [usernameValue, setUsernameValue] = useState('');
    const [isValidUsernameInput, setIsValidUsernameInput] = useState<boolean>(true);
    const [passwordValue, setPasswordValue] = useState<string>("");
    const [isEmptyUsername, setIsEmptyUsername] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [hasInvalidCharacters, setHasInvalidCharacters] = useState(false);
    const { sessionData, setSessionData } = useSessionData();



    useEffect(() => {
        if (sessionData.userData.role === "super_admin") {
            form.setFieldsValue({
                role: "",
                permissions: [],

            });
        }

    }, [form]);


    const handleInputBlur = async () => {
        setIsValidUsernameInput(true);
        setHasInvalidCharacters(false);

        const regex = /^[a-zA-Z0-9]+$/; // Solo letras y números, sin espacios ni caracteres especiales

        if (usernameValue === "") {
            setIsEmptyUsername(true);
        } else if (!regex.test(usernameValue)) {
            setHasInvalidCharacters(true);
        } else {
            setIsEmptyUsername(false);
            setHasInvalidCharacters(false);

            const response = await isValidUsername(usernameValue); // Tu función asíncrona
            if (response?.type === 1) {
                setIsValidUsernameInput(response.data);
            } else {
                setIsValidUsernameInput(false);
            }
        }
    };


    const closeDrawer = () => {
        form.resetFields();
        props.setDrawerVisible(false)
    }



    const onFinish = (values: any) => {
        if (isValidUsernameInput) {

            setLoading(true)
            const userData: NewUserData = {
                name: values.name,
                surname: values.surname,
                lastSurname: values["second-surname"],
                email: values.email,
                phone: values.phone,
                username: values.username,
                password: values.password,
                rol: values.role,
                language: values.language,
                notify: !!values.notifications,
                permissions : values.permissions
            };

            createUser(userData)
                .then((response) => {
                    
                    if (response?.type === 1) {

                        // si ha ido bien, entone tenemos que guardar bien los datos en el contexto
                        if (sessionData.userData.role === "super_admin" && values.role === "super_admin") {
                            const storedUserData = window.sessionStorage.getItem("user-data");
                            if (storedUserData) {
                                const parsedUserData: UserSessionData = JSON.parse(storedUserData);

                                // Actualizar solo el rol
                                parsedUserData.userData.role = "bo_manager"; // Cambia "nuevoRol" por el valor que necesites

                                // Guardar nuevamente en sessionStorage
                                window.sessionStorage.setItem("user-data", JSON.stringify(parsedUserData));
                                setSessionData(parsedUserData);
                            }
                        }

                        closeDrawer();
                    }
                    setLoading(false)
                });
        }
    };



    const handleBtnCancel = () => {
        closeDrawer();
    }
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const [selectedRole, setSelectedRole] = useState<string | undefined>(form.getFieldValue("role"));

    useEffect(() => {
        setSelectedRole(form.getFieldValue("role"));
    }, [form.getFieldValue("role")]);

    return (

        <div className="account-users-filter">
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={16}>

                    <Col xs={24} md={24}>
                        <h3>
                            {t("components.new-users.general")}
                        </h3>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="name"
                            rules={[{ required: true, message: t("components.new-users.validations.name-required") ?? "Name is required" }]}
                        >
                            <Input className="app-input"
                                placeholder={'' + t("components.new-users.name")} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="surname"
                            rules={[{ required: true, message: t("components.new-users.validations.surname-required") ?? "Surname is required" }]}
                        >
                            <Input className="app-input"
                                placeholder={t("components.new-users.surname") + ''} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="second-surname"
                        >
                            <Input className="app-input"
                                placeholder={t("components.new-users.second-surname") + ''} />
                        </Form.Item>
                    </Col>


                    <Col xs={24} md={24}>
                        <h3>
                            {t("components.new-users.contact")}
                        </h3>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: t("components.new-users.validations.email-required") + "" }]}

                        >
                            <Input className="app-input"
                                placeholder={t("components.new-users.email") + ""} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="phone"
                            rules={[
                                {
                                    pattern: /^[0-9]*$/,
                                    message: t("components.new-users.validations.phone-numeric") + "",
                                },
                            ]}
                        >
                            <Input className="app-input"
                                placeholder={t("components.new-users.phone") + ""} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <h3>
                            {t("components.new-users.access")}
                        </h3>
                    </Col>


                    <Col xs={24} md={24}>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: t("components.new-users.validations.password-required") + "" }]}
                            hasFeedback
                        >
                            <Input.Password className="app-input"
                                placeholder={t("components.new-users.password") + ""}
                                value={passwordValue} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="repeat-password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[{ required: true, message: t("components.new-users.validations.password-required") + "" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(t("components.new-users.validations.password-not-match") + "");
                                },
                            }),
                            ]}
                        >
                            <Input.Password className="app-input"
                                placeholder={t("components.new-users.repeat-password") + ""}
                                value={passwordValue} />
                        </Form.Item>
                    </Col>


                    <Col xs={24} md={24}>

                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: t("components.new-users.validations.username-required") + "" // Traducción con t()
                                },
                            ]}
                            validateStatus={(!isValidUsernameInput || isEmptyUsername || hasInvalidCharacters) ? 'error' : 'success'}
                            help={isEmptyUsername
                                ? t("components.new-users.validations.username-required")
                                : hasInvalidCharacters
                                    ? t("components.new-users.validations.username-caracteres-not-valid") // Mensaje específico para caracteres no válidos
                                    : !isValidUsernameInput && t("components.new-users.validations.username-not-valid")} // Mensaje de formato no válido
                        >
                            <Input className="app-input"
                                placeholder={t("components.new-users.username") + ""} // Traducción con t()
                                value={usernameValue}
                                onChange={(e) => setUsernameValue(e.target.value)} // Actualiza el valor del estado
                                onBlur={handleInputBlur} // Valida el input al perder el foco
                            />
                        </Form.Item>

                    </Col>


                    { // esta parte del forumario solo se muestra, si mi rol es super admin 

                        sessionData.userData.role === "super_admin" && ( // si mi rol es super admin entonces le puedo cambiar el rol
                            <>
                                <Col xs={24} md={24}>
                                    <h3>
                                        {t("components.edit-users.role")}
                                    </h3>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item name="role"
                                        rules={[{ required: true, message: t("components.edit-users.validations.rol-required") + "" }]} >
                                        <Select
                                            className="app-input"
                                            placeholder={t("components.edit-users.select")}
                                            onChange={(value) => {
                                                setSelectedRole(value); // Actualiza el estado

                                                if (value === "super_admin") {
                                                    setShowAlert(true);
                                                    form.setFieldsValue({ permissions: permissionsSuperAdmin });
                                                } else {
                                                    setShowAlert(false);
                                                    form.setFieldsValue({ acceptChangeRole: undefined });

                                                    if (value === "bo_manager") {
                                                        form.setFieldsValue({ permissions: permissionBoManager });
                                                    } else if (value === "bo_basic") {
                                                        form.setFieldsValue({ permissions: permissionBoBasic });
                                                    } else {
                                                        form.setFieldsValue({ permissions: [] });
                                                    }
                                                }
                                            }}>
                                            <Select.Option value="super_admin">{t("roles.super_admin")}</Select.Option>
                                            <Select.Option value="bo_manager">{t("roles.bo_manager")}</Select.Option>
                                            <Select.Option value="bo_basic">{t("roles.bo_basic")}</Select.Option>
                                            <Select.Option value="bo_customized">{t("roles.bo_customized")}</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={24}>
                                    <Form.Item name="permissions">
                                        <Select
                                            className="app-select"
                                            mode="multiple"
                                            placeholder={t("components.edit-users.permission")}
                                            onChange={(value) => { console.log(value) }}
                                            disabled={selectedRole !== "bo_customized"} // Se habilita solo si es "bo_customized"
                                            >
                                            {sectionOptions.map((section: Sections) => (
                                                <Select.Option key={section} value={section}>
                                                    {t(`sections.${section}`)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                </Col>
                                {showAlert && (
                                    <>

                                        <Form.Item
                                            name="acceptChangeRole"
                                            valuePropName="checked"
                                            rules={[
                                                {
                                                    validator: (_, value) =>
                                                        value ? Promise.resolve() : Promise.reject((t("components.edit-users.validations.checkbox-required")))
                                                }
                                            ]}
                                        >
                                            <Checkbox className="app-checkbox">
                                                {t("components.edit-users.checkbox-super-admin")}
                                            </Checkbox>
                                        </Form.Item>
                                    </>
                                )}

                            </>
                        )
                    }

                    <Col xs={24} md={24}>
                        <h3>
                            {t("components.new-users.language")}
                        </h3>
                    </Col>


                    <Col xs={24} md={24}>
                        <Form.Item
                            name="language"
                            rules={[{ required: true, message: t("components.new-users.validations.language-required") + "" }]}
                        >
                            <Select className="app-input" placeholder={t("components.new-users.select")}>
                                {props.languagesAvailable.map((language) => (
                                    <Select.Option value={language}>
                                        {t(`languages.${language}`)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item name="notifications">
                            <Checkbox>
                                {t("components.new-users.check-box")}
                            </Checkbox>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button className="app-button" style={{ width: "70%" }} onClick={handleBtnCancel}>
                                {t("components.new-users.btn-cancel")}
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button className="app-button" style={{ width: "70%" }} htmlType="submit" loading={loading} disabled={loading}>
                                {t("components.new-users.btn-save")}
                            </Button>
                        </Form.Item>
                    </Col>


                </Row>

            </Form>
        </div>
    );
};

export default NewUserForm;
