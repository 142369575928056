import { CheckCircleFilled, CheckCircleOutlined, ClockCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { HotelAvailabilityResponse } from "../models/availability/HotelAvailability";
import { LocationAvailabilityResponse } from "../models/availability/LocationsAvailability";
import moment from "moment";




export type UserType = "agent" | "independent" | "business" | "events";
export type NewStatus = "active" | "draft" | "inactive";
export type Sections = 
  | 'admin_accounts' 
  | 'bookings' 
  | 'statistics' 
  | 'news_management' 
  | 'contracts' 
  | 'account_assignment' 
  | 'payment_config' 
  | 'configuration' 
  | 'users';


  export const sectionOptions: Sections[] = [
    'admin_accounts', 
    'bookings', 
    'statistics', 
    'news_management', 
    'contracts', 
    'account_assignment', 
    'payment_config', 
    'configuration', 
    'users'
  ];


  
  export const permissionsSuperAdmin: Sections[] = [
    'admin_accounts', 
    'bookings', 
    'statistics', 
    'news_management', 
    'contracts', 
    'account_assignment', 
    'payment_config', 
    'configuration', 
    'users'
  ];

    
  export const permissionBoManager: Sections[] = [
    'admin_accounts', 
    'bookings', 
    'statistics', 
    'news_management', 
    'contracts', 
  ];

  export const permissionBoBasic: Sections[] = [
    'bookings', 
    'news_management', 
  ];





export const EngineDestinationTypeLocation: number = 1;
export const EngineDestinationTypeHotel: number = 2;

export const emptyLocationAvailabilityResponse: LocationAvailabilityResponse = { Hotels: [], LocationAvailabilities: [] };
export const emptyHotelAvailabilityResponse: HotelAvailabilityResponse = { Hotels: [], HotelAvailabilities: [], CrossSellingProducts: [] };
export const URL_IMAGE = "";
export const newStatus = [
    {
        key: 'active', // active = programada o publicada 
        label: 'components.communications.filter.publish',
    },
    {
        key: 'draft',
        label: 'components.communications.filter.drift',
    },
    {
        key: 'inactive',
        label: 'components.communications.filter.not-publish',
    },

];

export const newStatusSelector = [
    {
        key: '',
        label: 'components.communications.filter.everything', // t() se aplica en el uso
    },
    {
        key: 'active', // active = programada o publicada 
        label: 'components.communications.filter.active',
    },
    {
        key: 'draft',
        label: 'components.communications.filter.drift',
    },
    {
        key: 'inactive',
        label: 'components.communications.filter.not-publish',
    },
];


export const LanguageCodeToText = [
    { key: 'es-ES', label: 'languages.es-ES' },
    { key: 'es', label: 'languages.es' },
    { key: 'us', label: 'languages.us' },
    { key: 'en-US', label: 'languages.en-US' },
    { key: 'en-EN', label: 'languages.en-EN' },
    { key: 'en', label: 'languages.en' },
    { key: 'fr-FR', label: 'languages.fr-FR' },
    { key: 'fr', label: 'languages.fr' },
    { key: 'de', label: 'languages.de' },
    { key: 'pt', label: 'languages.pt' },
    { key: 'br', label: 'languages.br' },
    { key: 'pt-BR', label: 'languages.pt-BR' },
    { key: 'pt-PT', label: 'languages.pt-PT' },
    { key: 'mx', label: 'languages.mx' },
    { key: 'es-MX', label: 'languages.es-MX' },
    { key: 'de-DE', label: 'languages.de-DE' },
    { key: 'de', label: 'languages.de-DE' },
    { key: 'it-IT', label: 'languages.it-IT' },
    { key: 'it', label: 'languages.it-IT' },
];
export const PageSize = 10;



export const getCountryCode = (language: string): string => {
    switch (language) {
        case "de":
        case "de-DE":
            return "de";
        case "it":
        case "it-IT":
            return "it";



        case "es":
        case "es-ES":
            return "es";
        case "fr":
        case "fr-FR":
            return "fr";
        case "pt-PT":
            return "pt";
        case "pt-BR":
            return "br"
        case "en-US":
            return "us";
        case "es-MX":
            return "MX";
        default:
            return "gb"; // Establece en inglés por defecto
    }
};

export  const tagIconSwitch = (val: NewStatus | number, startDate: Date) => {
    switch (val) {
        case "active":
        case 0:
            return moment().isBefore(startDate) ? (
                <ClockCircleOutlined className="icons-switch" />
            ) : (
                <CheckCircleOutlined className="icons-switch" />
            );
        case "inactive":
        case 2:
            return <MinusCircleOutlined className="icons-switch" />;
        case "draft":
        case 1:
            return <ClockCircleOutlined className="icons-switch" />;
        default:
            return <></>;
    }
};


