import { Alert, Button, Checkbox, Col, Form, Input, Row, Select } from "antd";

import './AddContractComponent.scss';
import { useTranslation } from "react-i18next";
import CreateContract from "../../../models/CreateContract";
import { useEffect, useState } from "react";
import HotelInfo from "../../../models/HotelInfo";
import { set } from "lodash";

interface AddContractComponentProps {
    onFinish: (contractData: CreateContract) => void;
    onCancel: () => void;
    resetFields: number;
    loading: boolean;
    hotels: HotelInfo[];
}

const AddContractComponent = (props: AddContractComponentProps): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { onFinish, onCancel, resetFields } = props;

    const [loading, setLoading] = useState<boolean>(props.loading);


    useEffect(() => {
        setLoading(props.loading);
    },[props.loading]);


    useEffect(() => {
        form.resetFields();
        setIsAllSelected(false); 
        setSelectedHotels([]);
    }, [resetFields]);

    const onCancelAdd = (): void => {
        form.resetFields();
        onCancel();
    }
    

    const [selectedHotels, setSelectedHotels] = useState<string[]>([]);
    const allHotelIds = props.hotels.map(h => h.id);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

    const handleSelectChange = (value: any) => {
        let newSelectedHotels;
        if (value.includes("all") || value.length === allHotelIds.length) {
            newSelectedHotels = allHotelIds; // Selecciona todos
            setIsAllSelected(true);
        } else {
            newSelectedHotels = value;
            setIsAllSelected(false);
        }

        setSelectedHotels(newSelectedHotels);
    };



    useEffect(() => {
       form.setFieldValue('hotelsAffected', selectedHotels);
    },[selectedHotels]);


    const onAddContract = (): void => {
        let contract: CreateContract = {
            name: form.getFieldValue('name'),
            pmsCode: form.getFieldValue('pmsCode') ?? '',
            integrationCode: form.getFieldValue('integrationCode'),
            isBase: isBaseContract,
            isActive: isActive,
            hotelsAffected: selectedHotels
        }

        onFinish(contract);
    }

    const [isActive, setIsActive] = useState<boolean>(true);
    const [isBaseContract, setIsBaseContract] = useState<boolean>(false);
    useEffect(() => {
        if(isBaseContract){
            form.setFieldsValue({ hotelsAffected: props.hotels.map(x => x.id) }); 
        }
    }, [isBaseContract]);



    return (
        <div className="add-contract">
            <Form form={form} layout="vertical" onFinish={onAddContract} >
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item
                            name="name"
                            label={t("components.contract-form-add.name")}
                            rules={[{ required: true, message: t("components.contract-form-add.name-required") || "" }]}
                        >
                            <Input className="app-input" allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            name="pmsCode"
                            label={t("components.contract-form-add.pms-code")}
                        >
                            <Input className="app-input" allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            name="integrationCode"
                            label={t("components.contract-form-add.integration-code")}
                            rules={[{ required: true, message: t("components.contract-form-add.code-integration-required") || ""  }]}
                        >

                            <Input className="app-input" allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="isBaseContract" label={t("components.contract-form-add.is-base-contract")} initialValue={false}>
                            <Select onChange={setIsBaseContract} className="app-input" defaultValue={false} allowClear>
                                <Select.Option value={true}>{t("components.contract-form-add.is-base-contract-yes")}</Select.Option>
                                <Select.Option value={false}>{t("components.contract-form-add.is-base-contract-no")}</Select.Option>
                            </Select>
                        </Form.Item>
                        {
                            //If isBaseContract == true then show the next message:

                            isBaseContract && <Alert className="alert-base-message" message={t("components.contract-form-add.is-base-contract-message")} type="warning" />
                        }
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="isActive" label={t("components.contract-form-add.is-active")} initialValue={true}>
                            <Select onChange={setIsActive} className="app-input" allowClear>
                                <Select.Option value={true}>{t("components.contract-form-add.is-active-yes")}</Select.Option>
                                <Select.Option value={false}>{t("components.contract-form-add.is-active-no")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                    <Form.Item
                            name="hotelsAffected"
                            initialValue={[]}
                            label={t("components.contract-form-add.hotels-affected")}
                            rules={[{ required: true, message: t("components.contract-form-add.hotels-required") || "" }]}
                        >
                            <Select
                                disabled={isBaseContract}
                                allowClear
                                mode="multiple"
                                className="app-select"
                                value={selectedHotels}
                                onChange={handleSelectChange}
                                dropdownRender={menu => (
                                    <>
                                        <div style={{ padding: "8px", borderBottom: "1px solid #eee" }}>
                                            <Checkbox
                                                checked={isAllSelected}
                                                onChange={() => handleSelectChange(["all"])}
                                            >
                                                {t('components.contract-form-add.select-all-hotels')}
                                                </Checkbox>
                                        </div>
                                        {menu}
                                    </>
                                )}
                                options={props.hotels.map(hotel => ({
                                    label: hotel.name,
                                    value: hotel.id,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <div className="actions">
                        <Button disabled={loading} className="app-button danger" type="primary" onClick={onCancelAdd}>
                            {t("components.contract-form-add.cancel")}
                        </Button>
                        <Button disabled={loading}  loading={loading} className="app-button" type="primary" htmlType="submit">
                            {t("components.contract-form-add.save")}
                        </Button>
                    </div>
                </Row>
            </Form>
        </div>
    )
}

export default AddContractComponent;